import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import ApiService from "api/ApiService.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const ResetPassword = (props) => {
  const { t } = useTranslation();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showHome, setShowHome] = React.useState(false);

  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [redirectUrl, setRedirectUrl] = React.useState("/");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const showErrorMessage = (messageContent) => {
    setMessage(messageContent);
    setTimeout(() => {
      setPasswordError("");
      setConfirmPasswordError("");
      setMessage("");
    }, ApiService.messageTimeout);
  };

  const showSuccessMessage = (messageContent) => {
    setPasswordError("");
    setConfirmPasswordError("");
    setMessage("");
    setSuccessMessage(messageContent);
    setShowHome(true);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validPasswordLength = password.length > 6;
    const passwordsMatch = password === confirmPassword;
    const checksPassed = validPasswordLength && passwordsMatch;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPasswordLength) {
        setPasswordError(t('password-min-length'));
        messageContent.push(t('password-min-length'));
      }
      if (!passwordsMatch) {
        setConfirmPasswordError(t('passwords-not-match'));
        messageContent.push(t('passwords-not-match'));
      }
      showErrorMessage(`${t("invalid-information-provided")}: ${messageContent.join(", ")}.`);
      return;
    }
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
      passwordResetSecret: queryParams.get("passwordResetSecret"),
      password,
    };
    const frontendUrl = queryParams.get("frontendUrl");
    if (frontendUrl) {
      setRedirectUrl(frontendUrl);
    }

    try {
      const responseData = await ApiService.resetUserPassword(params);
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="white"
              >
                <h4 style={{ color: "#002144", marginTop: "15px" }}>
                  {t('reset-password')}
                </h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    required: true,
                    type: "password",
                    placeholder: "Password...",
                    autoComplete: "off",
                    onChange: (e) => {
                      setPassword(e.target.value);
                    },
                  }}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          repeat
                        </Icon>
                      </InputAdornment>
                    ),
                    required: true,
                    type: "password",
                    placeholder: t('password-again'),
                    autoComplete: "off",
                    onChange: (e) => {
                      setConfirmPassword(e.target.value);
                    },
                  }}
                  error={confirmPasswordError.length > 0}
                  helperText={confirmPasswordError}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {!showHome && (
                  <Button color="primary" size="lg" block type="submit">
                    {t('reset-password')}
                  </Button>
                )}
                {showHome && (
                  <Button
                    onClick={() => {
                      window.location.replace(redirectUrl);
                    }}
                    round
                    color="primary"
                    size="lg"
                  >
                    {t('return-home')}
                  </Button>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={message.length > 0}
        closeNotification={() => setMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
    </div>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ResetPassword;
