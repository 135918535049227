import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PictureUpload from "components/CustomUpload/PictureUpload";
import Typography from '@material-ui/core/Typography';

// core components
//import styles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

export default function ProfileCard({
  userId,
  image,
  documentType,
  name,
  organizations,
  email,
  props
}) {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div style={{width: 120}}>
        <PictureUpload
          userId={userId}
          documentType={documentType}
          image={image}
        />
      </div>
      <div className={classes.title}>
        <Typography variant='h6'>{name}</Typography>
        <div>{email}</div>
        {
          organizations?.map((organization, i) => (
            <div key={i}>
              {organization.role} of {organization.name}
            </div>
          ))
        }
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    backgroundColor: '#eeeeee',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    marginLeft: 15
  }
});

ProfileCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  handleClickAvatar: PropTypes.func
};
