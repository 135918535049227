import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import HttpsIcon from "@material-ui/icons/Https";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "api/ApiService.js";
import isEmail from "validator/lib/isEmail";
import SnackBarComponent from "components/SnackBarComponent.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const ResetPasswordEmail = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [showHome, setShowHome] = React.useState(false);

  // Error handling
  const [emailError, setEmailError] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError(t('invalid-email'));
      return;
    }

    try {
      const responseData = await ApiService.sendUserResetPasswordEmailLink({
        email,
      });
      setSnackbarMessage(responseData.message);
      setVariant("success");
      setOpenSnackbar(true);
      setShowHome(true);
    } catch (e) {
      setSnackbarMessage(e.message);
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const classes = useStyles();
  const showAdditionalInformation = true;

  return (
    <>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{t('reset-password')}</h2>
              <CardBody>
                <GridContainer justify="center">
                  {showAdditionalInformation && (
                    <GridItem xs={12} sm={12} md={5}>
                      <InfoArea
                        title={t('forgot-password')}
                        description={t('forgot-password-description')}
                        icon={HttpsIcon}
                        iconColor="primary"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={8} md={5}>
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: t('email'),
                          type: "email",
                          required: true,
                          onChange: (e) => {
                            setEmail(e.target.value);
                          },
                        }}
                        error={emailError.length > 0}
                        helperText={emailError}
                      />
                      <div
                        style={{ marginTop: "10px" }}
                        className={classes.center}
                      >
                        {!showHome && (
                          <Button round color="primary" type="submit">
                            {t('send-link')}
                          </Button>
                        )}
                        {showHome && (
                          <Link to="/">
                            <Button round color="primary">
                              {t('return-home')}
                            </Button>
                          </Link>
                        )}
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={snackbarMessage}
      />
    </>
  );
};

export default ResetPasswordEmail;
