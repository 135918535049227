import Dashboard from "views/Dashboard/Dashboard.js";
//import SharedDashboard from "views/Dashboard/SharedDashboard.js";
import Documents from "views/Pages/Documents.js";
import SharedDocuments from "views/Pages/SharedDocuments";
import Bin from "views/Pages/Bin.js";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Assessment from "views/Pages/Assessment";

var dashRoutes = [
  {
    collapse: true,
    name: "TEA",
    rtlName: "",
    //mini: "D",
    icon: LibraryBooksIcon,
    state: "teaPageCollapse",
    views: [
      {
        path: "/dashboard",
        name: "DASHBOARD",
        rtlName: "",
        mini: "D",
        state: "dashboardPageCollapse",
        component: Dashboard,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "DOCUMENTS",
        rtlName: "",
        mini: "D",
        state: "documentsPageCollapse",
        views: [
          {
            path: "/documents",
            name: "Outbox",
            rtlName: "",
            mini: "-",
            rtlMini: "",
            component: Documents,
            layout: "/admin",
          },
          {
            path: "/shared-documents",
            name: "Inbox",
            rtlName: "",
            mini: "-",
            rtlMini: "",
            component: SharedDocuments,
            layout: "/admin",
          },
          {
            path: "/bin",
            name: "Bin",
            rtlName: "",
            mini: "-",
            rtlMini: "",
            component: Bin,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "ESG",
    rtlName: "",
    //mini: "D",
    icon: EqualizerIcon,
    state: "esgPageCollapse",
    views: [
      {
        path: "/assesments",
        name: "ASSESSMENTS",
        rtlName: "",
        mini: "A",
        component: Assessment,
        layout: "/admin",
      },
    ],
  },

  //   {
  //     collapse: true,
  //     name: "FOLDER",
  //     rtlName: "",
  //     //icon: FolderOpenIcon,
  //     state: "containersPageCollapse",
  //     views: [
  //       {
  //         path: "/containers",
  //         name: "Outbox",
  //         rtlName: "",
  //         mini: "O",
  //         rtlMini: "",
  //         component: Containers,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/shared-containers",
  //         name: "Inbox",
  //         rtlName: "",
  //         mini: "I",
  //         rtlMini: "",
  //         component: SharedDocumentContainers,
  //         layout: "/admin"
  //       }
  //     ]
  //   },
];
export default dashRoutes;
