import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px 0",
    padding: "10px",
  },

  document: {
    margin: "1em 0",
  },

  reactPdf: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > div": {
      maxWidth: "calc(~'100% - 2em')",
      boxShadow: "0 0 8px rgba(0, 0, 0, .5)",
      margin: "0 0 1em 0",
      "& > canvas": {
        maxWidth: "100%",
        height: "auto !important",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
};

const useStyles = makeStyles(styles);

export default function PDFViewer({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pagesRendered, setPagesRendered] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPagesRendered(0);
  };

  const onRenderSuccess = () =>
    setPagesRendered((pagesRendered) => pagesRendered + 1);

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.document}>
          <Document
            file={data}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            loading={""}
            noData={t('pdf-returned-empty')}
            className={classes.reactPdf}
          >
            {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
              const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
              const isLastPage = numPages === index + 1;
              const needsCallbackToRenderNextPage =
                isCurrentlyRendering && !isLastPage;

              return (
                <Page
                  key={`page_${index + 1}`}
                  onRenderSuccess={
                    needsCallbackToRenderNextPage ? onRenderSuccess : null
                  }
                  pageNumber={index + 1}
                />
              );
            })}
          </Document>
        </div>
      </div>
    </div>
  );
}
