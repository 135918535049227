import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  details: {
    alignItems: "center",
    display: "block",
    // borderTop: '1px solid #f5f5f5',
  },
  expandIcon: {
    "& .MuiIconButton-root": {
      "@media (min-width: 960px)": {
        display: "none",
      },
    },
  },
  hideIcon: {
    color: "#eee",
  },
  disabled: {
    backgroundColor: "#EEE !important",
    "&::before": {
      backgroundColor: "#EEE",
    },
  },
}));

const ExpansionPanelComponent = ({
  panelSummary,
  panelDetails,
  index,
  handleAction = null,
  elevation = 0,
  hideIcon = false,
  disabled = false,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = (event, expand) => {
    setExpanded(expand);
  };

  const getSummaryClass = () => {
    const classObj = {};
    if (hideIcon) {
      classObj.root = classes.expandIcon;
    }
    if (disabled) {
      classObj.expandIcon = classes.hideIcon;
    }
    return classObj;
  };

  return (
    <ExpansionPanel
      elevation={elevation}
      onChange={(event, expanded) => handleExpand(event, expanded)}
      expanded={expanded}
      disabled={disabled}
      classes={{ disabled: classes.disabled }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id={index}
        classes={getSummaryClass()}
      >
        {panelSummary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {expanded && panelDetails}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExpansionPanelComponent;
