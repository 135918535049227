import React, { useState } from "react";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ApiService from "api/ApiService.js";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import LinkIcon from "@material-ui/icons/Link";
import CheckIcon from "@material-ui/icons/Check";
import ModalComponent from "components/ModalComponent.js";
import QRCode from "react-qr-code";
import HashTimeline from "../views/Pages/HashTimeline";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import { formatDate } from "api/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SendIcon from "@material-ui/icons/Send";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReplayIcon from "@material-ui/icons/Replay";
const ItemExpansionPanelDetails = ({
  item,
  id,
  handleUpdateView,
  profile,
  accessRights,
  refreshContainer,
  role,
  containerOwner,
  lookupUsers,
}) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();

  const [showAddEntry, setShowAddEntry] = useState(false);
  const [entryLoading, setEntryLoading] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [entryValue, setEntryValue] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [entryConfirmingParty, setEntryConfirmingparty] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [openBCModal, setOpenBCModal] = useState(false);
  const [signatureDetails, setSignatureDetails] = useState({});
  const [verificationData, setVerificationData] = useState([]);
  const [openVerifyHashModal, setOpenVerifyHashModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [openReverseDialog, setOpenReverseDialog] = useState(false);
  const [reverseEntry, setReverseEntry] = useState({});

  // modals for signature verification and view on chain
  const handleOpenBCModal = (signature, reverse = false) => {
    if (signature.length > 0) {
      setSignatureDetails(signature[reverse ? 1 : 0]);
      setOpenBCModal(true);
    }
  };

  const handleCloseBCModal = () => {
    setSignatureDetails({});
    setOpenBCModal(false);
  };

  const handleOpenVerifyHashModal = async (signature, reverse = false) => {
    if (signature.length > 0) {
      setOpenVerifyHashModal(true);
      try {
        const response = await ApiService.verifySignature({
          signatureId: signature[reverse ? 1 : 0]._id,
          parentId: signature[reverse ? 1 : 0].parentId,
          parentType: "METADATA",
        });
        setVerificationData(response.verificationSteps);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseVerifyHashModal = () => {
    setOpenVerifyHashModal(false);
  };

  const handleShowAddEntry = (bool) => {
    setEntryValue("");
    setEntryDate("");
    setEntryConfirmingparty("");
    setSelectedFile(null);
    setShowAddEntry(bool);
    setEntryLoading(false);
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      redirectPath: `/admin/metrics/assessment/${id}`,
    });
    return url.toString();
  };

  // sign entry - confirming party
  const handleSignEntry = async (entry, index) => {
    setRowIndex(index);
    setErrors("");
    setSignLoading(true);
    // sign entry
    const signParams = {
      parentId: entry._id,
      parentType: "METADATA",
      access: "PRIVATE",
    };
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(signParams, signal);
    } catch (e) {
      console.error(e.message);
      setErrors(e.message);
      setSignLoading(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          setErrors(t("error-downloading-package"));
          setSignLoading(false);
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        setErrors(t("operation-aborted"));
        setSignLoading(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setErrors(e.message);
      setSignLoading(false);
    } finally {
      // update view
      setSignLoading(false);
      refreshContainer();
    }
  };

  const handleOpenReverseDialog = (entry) => {
    setRowIndex(entry.metadata.index);

    setReverseEntry(entry);
    setOpenReverseDialog(true);
  };

  const closeReverseDialog = () => {
    setOpenReverseDialog(false);
  };

  // resend invite
  const handleResendInvite = async (entry, index) => {
    setErrors("");
    setRowIndex(index);
    setResendLoading(true);
    const inviteParams = {
      parentId: id,
      parentType: "CONTAINER",
      accessRightsId: accessRights.filter(
        (user) => user.email === entry.accessRights[0].email
      )[0]._id,
      frontendUrl: getFrontendUrl(),
    };
    try {
      await ApiService.inviteAccessRights(inviteParams);
      //handleRefresh();
    } catch (e) {
      console.error(e);
      setErrors(e.message);
    } finally {
      setResendLoading(false);
    }
  };

  const checkIfSigned = (entry) => {
    const signed = entry.signatures.filter(
      (signature) => lookupUsers[signature.sender] === profile.email
    );
    return signed.length > 0 ? true : false;
  };

  // add metadata - file upload - give access right - send invite
  const handleAddSendEntry = async () => {
    const validEntryValue = entryValue.length > 0;
    const validEntryDate = entryDate.length > 0;
    const validCntryConfirmingParty = entryConfirmingParty.length > 0;

    setErrors("");
    const checksPassed =
      validEntryValue && validEntryDate && validCntryConfirmingParty;
    if (!checksPassed) {
      if (!validEntryValue) {
        setErrors(t("invalid-value"));
        return;
      }
      if (!validEntryDate) {
        setErrors(t("invalid-date"));
        return;
      }
      if (!validCntryConfirmingParty) {
        setErrors(t("invalid-confirming-party"));
        return;
      }
    }

    const index =
      item.entry.length > 0
        ? item.entry[item.entry.length - 1].metadata.index + 1
        : 1;

    const params = {
      parentId: id,
      parentType: "CONTAINER",
      name: `esgMetricEntry_${item.name[10]}_${index}`,
      type: "application/json",
      metadata: {
        index,
        type: "metricEntry",
        value: entryValue,
        unit: item.metadata.unit,
        date: entryDate,
      },
      flags: [
        { name: "esg", value: true },
        { name: "esgMetric", value: true },
      ],
    };

    setEntryLoading(true);

    let responseMetadata;
    try {
      responseMetadata = await ApiService.addMetadata(params);
    } catch (e) {
      console.error(e);
      setEntryLoading(false);
      setErrors(e.message);
    }

    const name = `esgMetricEntry_${item.name[10]}_${index}`;
    const metadataId = responseMetadata.metadata.filter(
      (data) => data.name === name
    )[0]._id;

    // upload file
    if (selectedFile) {
      const fileTTL = "-1";

      const params = {
        parentId: id,
        file: selectedFile,
        shareable: "true",
        ttl: fileTTL.toString(),
        encoding: ApiService.storageFormat,
        title: fileTitle,
        message: t("file-upload"),
        flags: JSON.stringify([{ name: "esg", value: true }]),
      };
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        const keyParams = {};
        const { address } = await ApiService.getPublicKey(keyParams, signal);
        params.receivers = JSON.stringify([address]);
      } catch (e) {
        setErrors(t("error-fetching-user-data"));
        setEntryLoading(false);
        return;
      }
      // Upload file to server
      let responseData;
      try {
        responseData = await ApiService.uploadFile(params, signal);
      } catch (e) {
        setErrors(e.message);
        setLoading(false);
        return;
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            setErrors(t("error-uploading-file"));
            setLoading(false);
            setEntryLoading(false);
            return;
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            setLoading(false);
            const dataId =
              responseData.details.blockchainTransactions[0].data_id;
            // update metadata
            const updatedParams = {
              parentId: id,
              parentType: "CONTAINER",
              metadataId,
              name: `esgMetricEntry_${item.name[10]}_${index}`,
              type: "application/json",
              metadata: {
                index,
                type: "metricEntry",
                value: entryValue,
                unit: item.metadata.unit,
                date: entryDate,
                attachments: JSON.stringify([dataId]),
              },
              flags: [
                { name: "esg", value: true },
                { name: "esgMetric", value: true },
              ],
            };
            try {
              await ApiService.updateMetadata(updatedParams);
              setShowAddEntry(false);
              //handleUpdateView(response);
            } catch (e) {
              console.error(e);
              setEntryLoading(false);
              setErrors(e.message);
            }
          }
        }
        if (signal.aborted) {
          setErrors(t("operation-aborted"));
          setLoading(false);
          setEntryLoading(false);
          return;
        }
      } catch (e) {
        setErrors(t("error-uploading-file"));
        setLoading(false);
        setEntryLoading(false);
        return;
      }
    }
    // check if already has access to container
    const hasAccess = accessRights.filter(
      (access) =>
        access.email === entryConfirmingParty && access.type === "READ"
    );
    let returnDataContainerAccess;
    if (hasAccess.length === 0) {
      try {
        returnDataContainerAccess = await ApiService.addAccessRights({
          parentId: id,
          parentType: "CONTAINER",
          type: "READ",
          email: entryConfirmingParty,
          endOfLife: "-1",
        });
        //setAddAccessResponse(returnData);
      } catch (e) {
        console.error(e);
        setEntryLoading(false);
        setErrors(e.message);
      }
    }
    // add access right to metadata entry
    try {
      await ApiService.addAccessRights({
        parentId: metadataId,
        parentType: "METADATA",
        type: "SIGN",
        email: entryConfirmingParty,
        endOfLife: "-1",
      });
    } catch (e) {
      console.error(e);
      setEntryLoading(false);
      setErrors(e.message);
    }
    const inviteParams = {
      parentId: id,
      parentType: "CONTAINER",
      accessRightsId:
        hasAccess.length > 0
          ? hasAccess.filter((user) => user.email === entryConfirmingParty)[0]
              ._id
          : returnDataContainerAccess.accessRights.filter(
              (user) => user.email === entryConfirmingParty
            )[0]._id,
      frontendUrl: getFrontendUrl(),
    };
    try {
      await ApiService.inviteAccessRights(inviteParams);
    } catch (e) {
      console.error(e);
      setEntryLoading(false);
      setErrors(e.message);
    }
    // sign entry
    const signParams = {
      parentId: metadataId,
      parentType: "METADATA",
      access: "PRIVATE",
    };
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(signParams, signal);
    } catch (e) {
      console.error(e.message);
      setEntryLoading(false);
      setErrors(e.message);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          setEntryLoading(false);
          setErrors(t("error-downloading-package"));
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        setErrors(t("operation-aborted"));
        setEntryLoading(false);
      }
    } catch (e) {
      setEntryLoading(false);
      setErrors(e.message);
    } finally {
      setShowAddEntry(false);
      refreshContainer();
    }
  };

  const [reverseLoading, setReverseLoading] = useState(false);

  // reverse
  const handleReverseEntry = async (entry) => {
    closeReverseDialog();

    setReverseLoading(true);
    const metadataId = entry._id;

    // update metadata
    const updatedParams = {
      parentId: id,
      parentType: "CONTAINER",
      metadataId,
      metadata: {
        index: entry.metadata.index,
        type: entry.metadata.type,
        value: entry.metadata.value,
        unit: entry.metadata.unit,
        date: entry.metadata.date,
        attachments: entry.metadata.attachments
          ? entry.metadata.attachments
          : JSON.stringify([]),
        reverse: true,
      },
      flags: [
        { name: "esg", value: true },
        { name: "esgMetric", value: true },
        { name: "reverse", value: true },
      ],
    };
    try {
      await ApiService.updateMetadata(updatedParams);
    } catch (e) {
      console.error(e);
      setErrors(e.message);
    }

    // sign entry
    const signParams = {
      parentId: metadataId,
      parentType: "METADATA",
      access: "PRIVATE",
    };
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      await ApiService.addSignature(signParams, signal);
      refreshContainer();
    } catch (e) {
      console.error(e.message);
      setErrors(e.message);
    } finally {
      setReverseLoading(false);
    }
  };

  // delete entry
  //   const handleDeleteEntry = async (entryId) => {
  //     setErrors("");
  //     const abortController = new AbortController();
  //     const signal = abortController.signal;
  //     // delete header
  //     const params = {
  //       parentId: id,
  //       parentType: "CONTAINER",
  //       metadataId: entryId,
  //     };
  //     try {
  //       const response = await ApiService.deleteMetadata(params, signal);
  //       handleUpdateView(response);
  //     } catch (e) {
  //       console.error(e);
  //       setErrors(e.message);
  //     }
  //   };

  // delete metric
  const handleDeleteMetric = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // delete header
    const params = {
      parentId: id,
      parentType: "CONTAINER",
      metadataId: item._id,
    };
    try {
      const response = await ApiService.deleteMetadata(params, signal);
      if (item.entry.length === 0) {
        handleUpdateView(response);
      }
    } catch (e) {
      console.error(e);
    }
    item.entry.map(async (entry, index) => {
      const params = {
        parentId: id,
        parentType: "CONTAINER",
        metadataId: entry._id,
      };
      try {
        const response = await ApiService.deleteMetadata(params, signal);
        if (index === item.entry.length - 1) {
          handleUpdateView(response);
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  // download attachment
  const handleDownload = (attachment) => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      let responseData;
      const packageParams = {
        data_id: attachment.data_id,
        hash: attachment.hash,
        sender: attachment.sender,
        receiver: attachment.receiver,
      };
      try {
        responseData = await ApiService.prepareDownloadFile(
          packageParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handleDownloadFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
      }
    };
    handlePrepareFileDownload();
  };

  const fileInput = React.createRef();
  return (
    <>
      {item?.entry?.length > 0 ? (
        <>
          {item.entry.map((entry, index) => {
            return (
              <GridContainer
                key={index}
                spacing={2}
                alignItems="center"
                style={{ marginBottom: "30px" }}
              >
                <GridItem xs={12}>
                  {errors.length > 0 &&
                    index + 1 === rowIndex &&
                    !showAddEntry && <h6 style={{ color: "red" }}>{errors}</h6>}
                  {(signLoading || resendLoading) && index + 1 === rowIndex && (
                    <div className={classes2.root}>
                      <LinearProgress
                        style={{ marginRight: "15px", marginBottom: "15px" }}
                      />
                    </div>
                  )}
                  {reverseLoading && index + 1 === rowIndex && (
                    <div className={classes2.root}>
                      <LinearProgress
                        style={{ marginTop: "15px", marginRight: "30px" }}
                      />
                    </div>
                  )}
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "baseline",
                    }}
                  >
                    <h6 className={classes.cardCategory}>
                      {`${t("posting-number")}`}
                    </h6>
                    <p style={{ marginLeft: "15px" }}>
                      {`${entry.metadata.index}`}
                    </p>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "baseline",
                    }}
                  >
                    <h6
                      className={classes.cardCategory}
                      style={{ color: "rgba(0, 0, 0, 0.87)" }}
                    >{`${t("value")}`}</h6>
                    <p style={{ marginLeft: "15px", fontWeight: "400" }}>
                      {`${entry.metadata.value} ${entry.metadata.unit}`}
                    </p>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "baseline",
                    }}
                  >
                    <h6 className={classes.cardCategory}>
                      {`${t("ESG-date")}`}
                    </h6>
                    <p style={{ marginLeft: "15px" }}>
                      {formatDate(entry.metadata.date)}
                    </p>
                  </div>
                </GridItem>

                {entry.flags.filter((flag) => flag.name === "reverse").length >
                0 ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "inline-flex", alignItems: "baseline" }}
                    >
                      <h6
                        className={classes.cardCategory}
                        style={{ color: "#3F91F9" }}
                      >
                        {`${t("posting")} ${entry.metadata.index} ${t(
                          "offset-by-addendum"
                        )} ${entry.metadata.index}`}
                      </h6>
                      <div>
                        <Tooltip title={t("view-on-chain")}>
                          <IconButton
                            aria-label="view-on-chain"
                            style={{
                              color: "#3F91F9",
                              transform: "scale(0.8)",
                            }}
                            onClick={() =>
                              handleOpenBCModal(
                                entry.signatures.filter(
                                  (sig) => sig.sender === entry.user
                                ),
                                true
                              )
                            }
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("verify-hash")}>
                          <IconButton
                            style={{
                              color: "#3F91F9",
                              transform: "scale(0.8)",
                            }}
                            onClick={() =>
                              handleOpenVerifyHashModal(
                                entry.signatures.filter(
                                  (sig) => sig.sender === entry.user
                                ),
                                true
                              )
                            }
                          >
                            <CheckIcon className={classes.icons} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </GridItem>
                ) : (
                  <>
                    <GridItem xs={12} sm={6} md={3}>
                      {entry.attachments.length > 0 && (
                          <Tooltip title={t("download-attachment")}>
                            <IconButton
                              aria-label="download"
                              className={classes.margin}
                              style={{ color: "#4b679d", fontSize: "1em" }}
                              onClick={() =>
                                handleDownload(entry.attachments[0])
                              }
                            >
                              <GetAppIcon
                                style={{
                                  fontSize: "1em",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                      )}
                      {role === "owner" && (
                        <>
                          {/*   <Tooltip title={t("delete")}>
                        <IconButton
                          aria-label="delete"
                          style={{ fontSize: "1em" }}
                          onClick={() => handleDeleteEntry(entry._id)}
                        >
                          <ClearIcon
                            style={{
                              color: "#a5abbd",
                              fontSize: "1em",
                            }}
                          />
                        </IconButton>
                      </Tooltip> */}
                          <Tooltip
                            title={t("make-addendum-to-offset-this-posting")}
                          >
                            <IconButton
                              aria-label="reverse"
                              style={{ fontSize: "1em" }}
                              onClick={() => handleOpenReverseDialog(entry)}
                            >
                              <ReplayIcon
                                style={{
                                  color: "#a5abbd",
                                  fontSize: "1em",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("resend")}>
                            <IconButton
                              aria-label="send"
                              style={{ fontSize: "1em" }}
                              onClick={() => handleResendInvite(entry, index)}
                            >
                              <SendIcon
                                style={{
                                  color: "#3F91F9",
                                  fontSize: "1em",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {role === "confirming-party" &&
                        !checkIfSigned(entry) &&
                        entry.accessRights[0]?.email === profile.email && (
                          <div>
                            <Button
                              color="primary"
                              onClick={() => handleSignEntry(entry, index)}
                            >
                              {t("sign")}
                            </Button>
                          </div>
                        )}
                    </GridItem>
                  </>
                )}
                {entry.flags.filter((flag) => flag.name === "reverse").length >
                  0 && (
                  <>
                    <GridItem xs={12} sm={6} md={3}>
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                        }}
                      >
                        <h6 className={classes.cardCategory}>
                          {`${t("addendum-number")}`}
                        </h6>
                        <p style={{ marginLeft: "15px" }}>
                          {`${entry.metadata.index}`}
                        </p>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={3}>
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                        }}
                      >
                        <h6
                          className={classes.cardCategory}
                          style={{ color: "rgba(0, 0, 0, 0.87)" }}
                        >{`${t("value")}`}</h6>
                        <p style={{ marginLeft: "15px", fontWeight: "400" }}>
                          {`-${entry.metadata.value} ${entry.metadata.unit}`}
                        </p>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={3}>
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                        }}
                      >
                        <h6 className={classes.cardCategory}>
                          {`${t("addendum-date")}`}
                        </h6>
                        <p style={{ marginLeft: "15px" }}>
                          {`${formatDate(
                            entry.signatures.filter(
                              (sig) => sig.sender === entry.user
                            )[1]?.createdAt
                          )}`}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}></GridItem>
                  </>
                )}

                {
                  <>
                    <GridItem xs={12} sm={6}>
                      <div
                        style={{
                          border: "1px solid #AAAAAA",
                          borderRadius: "15px 15px",
                          margin: "0px 10px 10px 0px",
                          padding: "10px 0px",
                          display: "grid",
                          backgroundColor:
                            entry.flags.filter(
                              (flag) => flag.name === "reverse"
                            ).length > 0
                              ? "rgba(0,0,0,0.05)"
                              : "",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "100px" }}
                          >
                            {t("esg-accountant")}
                          </h6>
                          <p
                            style={{ marginLeft: "20px" }}
                          >{`${containerOwner}`}</p>
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "100px" }}
                          >
                            {t("status")}
                          </h6>
                          <p style={{ marginLeft: "20px" }}>{`${t(
                            "signed"
                          )}`}</p>
                        </div>

                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "100px" }}
                          >
                            {t("signature-date")}
                          </h6>
                          <p style={{ marginLeft: "20px" }}>{`${formatDate(
                            entry.signatures.filter(
                              (sig) => sig.sender === entry.user
                            )[0]?.createdAt
                          )}`}</p>
                        </div>
                        <hr
                          style={{
                            color: "#eeeeee",
                            width: "90%",
                            margin: "0 0 0 20px",
                          }}
                        />

                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "10px",
                          }}
                        >
                          <Tooltip title={t("view-on-chain")}>
                            <IconButton
                              aria-label="view-on-chain"
                              style={{
                                color: "#3F91F9",
                                transform: "scale(0.8)",
                              }}
                              onClick={() =>
                                handleOpenBCModal(
                                  entry.signatures.filter(
                                    (sig) => sig.sender === entry.user
                                  )
                                )
                              }
                            >
                              <LinkIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("verify-hash")}>
                            <IconButton
                              style={{
                                color: "#3F91F9",
                                transform: "scale(0.8)",
                              }}
                              onClick={() =>
                                handleOpenVerifyHashModal(
                                  entry.signatures.filter(
                                    (sig) => sig.sender === entry.user
                                  )
                                )
                              }
                            >
                              <CheckIcon className={classes.icons} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <div
                        style={{
                          border: "1px solid #AAAAAA",
                          borderRadius: "15px 15px",
                          margin: "0px 10px 10px 0px",
                          padding: "10px 0px",
                          display: "grid",
                          backgroundColor:
                            entry.flags.filter(
                              (flag) => flag.name === "reverse"
                            ).length > 0
                              ? "rgba(0,0,0,0.05)"
                              : "",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "110px" }}
                          >
                            {t("confirming-party")}
                          </h6>
                          <p
                            style={{ marginLeft: "20px" }}
                          >{`${entry.accessRights[0]?.email}`}</p>
                        </div>

                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "110px" }}
                          >
                            {t("status")}
                          </h6>
                          <p style={{ marginLeft: "20px" }}>{`${
                            entry.signatures.filter(
                              (sig) =>
                                lookupUsers[sig.sender] ===
                                entry.accessRights[0]?.email
                            ).length > 0
                              ? t("signed")
                              : t("waiting-for-signature")
                          }`}</p>
                        </div>

                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "20px",
                          }}
                        >
                          <h6
                            className={classes.cardCategory}
                            style={{ width: "110px" }}
                          >
                            {t("signature-date")}
                          </h6>
                          <p style={{ marginLeft: "20px" }}>{`${
                            entry.signatures.filter(
                              (sig) =>
                                lookupUsers[sig.sender] ===
                                entry.accessRights[0]?.email
                            )[0]?.createdAt
                              ? formatDate(
                                  entry.signatures.filter(
                                    (sig) =>
                                      lookupUsers[sig.sender] ===
                                      entry.accessRights[0]?.email
                                  )[0]?.createdAt
                                )
                              : t("waiting for signature")
                          }`}</p>
                        </div>
                        <hr
                          style={{
                            color: "#eeeeee",
                            width: "90%",
                            margin: "0 0 0 20px",
                          }}
                        />

                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            marginLeft: "10px",
                          }}
                        >
                          <Tooltip title={t("view-on-chain")}>
                            <IconButton
                              aria-label={t("view-on-chain")}
                              style={{
                                color: "#3F91F9",
                                transform: "scale(0.8)",
                              }}
                              onClick={() =>
                                handleOpenBCModal(
                                  entry.signatures.filter(
                                    (sig) =>
                                      lookupUsers[sig.sender] ===
                                      entry.accessRights[0]?.email
                                  )
                                )
                              }
                            >
                              <LinkIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("verify-hash")}>
                            <IconButton
                              style={{
                                color: "#3F91F9",
                                transform: "scale(0.8)",
                              }}
                              onClick={() =>
                                handleOpenVerifyHashModal(
                                  entry.signatures.filter(
                                    (sig) =>
                                      lookupUsers[sig.sender] ===
                                      entry.accessRights[0]?.email
                                  )
                                )
                              }
                            >
                              <CheckIcon className={classes.icons} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </GridItem>
                  </>
                }
              </GridContainer>
            );
          })}

          {!showAddEntry && role === "owner" && (
            <>
              <Tooltip title={t("delete-metric")}>
                <IconButton
                  aria-label="delete"
                  style={{ fontSize: "1em" }}
                  onClick={handleDeleteMetric}
                >
                  <ClearIcon
                    style={{
                      color: "#a5abbd",
                      fontSize: "1em",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Button color="primary" onClick={handleShowAddEntry}>
                {t("add-new-entry")}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <>
            {!showAddEntry && !entryLoading && (
              <>
                <Card pricing plain>
                  <CardBody pricing>
                    <div className={classes.icon}>
                      <EditIcon className={classes.iconPrimary} />
                    </div>
                    <h5
                      className={`${classes.cardTitle} ${classes.marginTop30}`}
                    >
                      {t("no-entries-yet")}
                    </h5>
                  </CardBody>
                </Card>

                <Tooltip title={t("delete-metric")}>
                  <IconButton
                    aria-label="delete"
                    style={{ fontSize: "1em" }}
                    onClick={handleDeleteMetric}
                  >
                    <ClearIcon
                      style={{
                        color: "#a5abbd",
                        fontSize: "1em",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Button color="primary" onClick={handleShowAddEntry}>
                  {t("add-new-entry")}
                </Button>
              </>
            )}
          </>
        </>
      )}
      {showAddEntry && (
        <>
          <CardHeader color="primary" icon>
            <h4 className={classes.cardIconTitle}>{t("add-new-entry")}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  labelText={t("value")}
                  id="help-text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: entryValue || "",
                    onChange: (e) => {
                      setEntryValue(e.target.value);
                    },
                    placeholder: t("value"),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  labelText={t("unit")}
                  id="help-text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: item.metadata.unit,

                    placeholder: t("unit"),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl fullWidth style={{ paddingTop: "24px" }}>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: t("date") }}
                    value={
                      entryDate !== null && entryDate.length > 0
                        ? formatDate(entryDate)
                        : null
                    }
                    onChange={(e) => setEntryDate(moment(e).toISOString())}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  labelText={t("confirming-party")}
                  id="help-text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: entryConfirmingParty || "",
                    onChange: (e) => {
                      setEntryConfirmingparty(e.target.value);
                    },
                    placeholder: t("confirming-party"),
                  }}
                />
              </GridItem>
              <GridItem item xs={12} sm={12}>
                {errors.length > 0 && (
                  <h6 style={{ color: "red" }}>{errors}</h6>
                )}

                <div
                  className="picture-container"
                  style={{ border: "1px dashed #AAAAAA", borderRadius: "10px" }}
                >
                  <div className="picture1">
                    <CloudUploadIcon
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                        color: "#4b679d",
                        paddingTop: "1em",
                      }}
                    />
                    <label
                      htmlFor="files"
                      className="btn"
                      style={{ display: "block" }}
                    >
                      {t("select-file-0")}
                    </label>
                    <input
                      type="file"
                      id="files"
                      accept="application/pdf"
                      ref={fileInput}
                      disabled={loading}
                      onChange={(e) => {
                        setLoading(false);
                        setErrors("");

                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          setFileTitle(selectedFile.name);
                          setSelectedFile(selectedFile);
                        }
                      }}
                    />
                  </div>
                  <h6 className="description">{selectedFile && fileTitle}</h6>
                </div>
              </GridItem>
            </GridContainer>
            {errors.length > 0 && <h6 style={{ color: "red" }}>{errors}</h6>}

            {entryLoading && (
              <div className={classes2.root}>
                <LinearProgress
                  style={{ marginTop: "15px", marginRight: "30px" }}
                />
              </div>
            )}

            <div style={{ display: "inline" }}>
              <div
                style={{
                  float: "right",
                  marginRight: "30px",
                  marginTop: "15px",
                }}
              >
                <Button
                  disabled={entryLoading}
                  simple
                  color="rose"
                  onClick={() => handleShowAddEntry(false)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  disabled={entryLoading}
                  color="primary"
                  onClick={handleAddSendEntry}
                >
                  {t("add-and-send")}
                </Button>
              </div>
            </div>
          </CardBody>
        </>
      )}

      <ModalComponent
        open={openBCModal}
        handleClose={handleCloseBCModal}
        dialogTitle={t("view-on-chain")}
        maxWidth="xs"
        dialogContent={
          <>
            {!isObjectEmpty(signatureDetails) ? (
              <div>
                {signatureDetails.transactionStatus === "PREPARED" ? (
                  <p>{t("transaction-is-presigned-but-not-on-blockchain")}</p>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <QRCode
                      size={200}
                      value={`https://whatsonchain.com/tx/${signatureDetails.transactionId}`}
                    />
                    <div style={{ textAlign: "center", marginTop: "2rem" }}>
                      <a
                        href={`https://whatsonchain.com/tx/${signatureDetails.transactionId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {"link"}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        }
      />

      <ModalComponent
        open={openVerifyHashModal}
        handleClose={handleCloseVerifyHashModal}
        dialogTitle={t("document-hash-signature-verification")}
        maxWidth="md"
        dialogContent={
          <>
            {verificationData.length > 0 && (
              <HashTimeline steps={verificationData} />
            )}
          </>
        }
      />

      <ModalComponent
        open={openReverseDialog}
        handleClose={closeReverseDialog}
        dialogTitle={t("make-addendum-to-offset-this-posting")}
        dialogContent={
          <div>{`${t(
            "are-you-sure-you-want-to-create-an-addendum-to-offset-posting"
          )} ${
            !isObjectEmpty(reverseEntry) ? reverseEntry.metadata.index : ""
          }?`}</div>
        }
        maxWidth="sm"
        dialogActions={
          <React.Fragment>
            <Button simple onClick={closeReverseDialog} color="rose">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleReverseEntry(reverseEntry)}
            >
              Yes
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
};

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#e8d1b3",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DBB686",
    },
  },
});

export default ItemExpansionPanelDetails;
