import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui/icons
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle.js";

import ApiService from "api/ApiService.js";

const useStyles = makeStyles(styles);

const ValidateEmail = (props) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [redirectUrl, setRedirectUrl] = React.useState("/");

  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      parentId: queryParams.get("parentId"),
      parentType: queryParams.get("parentType"),
      emailValidationSecret: queryParams.get("emailValidationSecret"),
    };
    const frontendUrl = queryParams.get("frontendUrl");
    if (frontendUrl) {
      setRedirectUrl(frontendUrl);
    }
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const responseData = await ApiService.userValidateEmail(params, signal);
        setSuccessMessage(responseData.message);
      } catch (e) {
        setErrorMessage(e.message);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.location.search]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Email Validation</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  {successMessage.length === 0 && errorMessage.length === 0 && (
                    <InfoArea
                      title="Validation in progress"
                      description="Email validation in progress ..."
                      icon={HourglassEmpty}
                      iconColor="primary"
                    />
                  )}
                  {successMessage.length > 0 && (
                    <InfoArea
                      title="Success"
                      description={successMessage}
                      icon={CheckCircleOutline}
                      iconColor="success"
                    />
                  )}
                  {errorMessage.length > 0 && (
                    <InfoArea
                      title="Error"
                      description={errorMessage}
                      icon={ErrorOutline}
                      iconColor="danger"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                {(successMessage.length > 0 || errorMessage.length > 0) && (
                  <React.Fragment>
                    <br></br>
                    <br></br>
                    <Button
                      onClick={() => {
                        window.location.replace(redirectUrl);
                      }}
                      round
                      color="primary"
                    >
                      Return to Home
                    </Button>
                  </React.Fragment>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

ValidateEmail.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ValidateEmail;
