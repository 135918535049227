/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ApiService from "api/ApiService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { fluid, white, rtlActive, hideSupport } = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
            [" " + classes.whiteColor]: white
        });
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    });

    const yearAndVersion = <div>
        <p className={classes.right}>
            v. {ApiService.version} &nbsp;
            &copy; {1900 + new Date().getYear()}{" "}
            <a
                href="https://abendum.com/"
                className={anchor}
                target="_blank"
            >
                {"Abendum"}
            </a>
        </p>
    </div>

    return (
        <footer className={classes.footer}>
            <div className={container}>
                {
                    hideSupport ?
                        yearAndVersion
                        :
                        <>
                            <p className={classes.left}>
                                <span>{t('support')}: </span>
                                <a href="mailto:torje@abendum.com" style={{ color: "#4b679d" }}>torje@abendum.com</a>
                                <span> , </span>
                                <a href="tel:+47 97501075" style={{ color: "#4b679d" }}>+47 97501075</a>
                            </p>
                            {yearAndVersion}
                        </>
                }
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};
