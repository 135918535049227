import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import ApiService from "api/ApiService.js";
import { Link } from "react-router-dom";
import { formatDate } from "api/utils";
import BackupIcon from "@material-ui/icons/Backup";
import SnackBarComponent from "components/SnackBarComponent.js";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "@material-ui/lab/Pagination";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import extendedTablesStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import ClearIcon from "@material-ui/icons/Clear";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import FilterSortView from "../../components/FilterSortView";
import ReactTable from "react-table";
import "./style.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#4b679d",
      "&:hover": {
        backgroundColor: "#4b679d",
      },

      "&:active": {
        backgroundColor: "#4b679d",
      },
    },
  },
  ...extendedTablesStyles,
  ...extendedFormsStyle,
}));

const Documents = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [myFiles, setMyFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // pagination
  const [sendDataCount, setSendDataCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [userProfile, setUserProfile] = React.useState({});

  // list view
  const [showListView, setShowListView] = React.useState(true);
  //filters
  const [filters, setFilters] = React.useState("");
  const [sortBy, setSortBy] = React.useState("createdAt:desc");
  const [filterParams, setFilterParams] = React.useState({});
  const [showFilterSort, setShowFilterSort] = React.useState(false);

  const handleShowFilterSort = () => {
    setShowFilterSort((prevState) => !prevState);
  };

  const handleSetSearchFilters = (value) => {
    setFilters(value);
  };

  const handleShowListView = (bool) => {
    setShowListView(bool);
  };

  const handleSearchTitle = () => {
    if (filters.length > 0) {
      setFilterParams({ title: { $regex: `${filters}`, $options: "/i" } });
    }
  };

  const handleSimple = (event) => {
    setSortBy(event.target.value);
  };

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleMoveToBin = async (data_id, sender, receiver, hash) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      data_id,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([
        { name: "abendum", value: true },
        { name: "bin", value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      handleRefresh();
    } catch (e) {
      console.error(e);
      setSnackbarMessage(e.message);
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const getQueryParams = React.useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;

      return params;
    },
    [props.location.search]
  );

  const handleRefresh = React.useCallback(
    async (signal = undefined) => {
      const getSendData = async (signal = undefined, queryParams) => {
        try {
          const myAddress = await ApiService.getPublicKey(
            {
              userId: queryParams.userId,
              serviceType: "SDC",
              keyType: "MASTER",
            },
            signal
          );
          let params;
          if (showListView) {
            params = {
              userId: queryParams.userId,
              sortBy: sortBy,
              receiver: myAddress.address,
              populateSignatures: true,
              populateMetadata: true,
            };
          } else {
            params = {
              userId: queryParams.userId,
              sortBy: sortBy,
              fromEntry: (currentPage - 1) * pageSize,
              numberOfResults: pageSize,
              receiver: myAddress.address,
            };
          }

          if (!ApiService.production) {
            if (!isObjectEmpty(filterParams)) {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": "abendum" },
                  { "flags.name": { $ne: "bin" } },
                  filterParams,
                ],
              });
            } else {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": "abendum" },
                  { "flags.name": { $ne: "bin" } },
                ],
              });
            }
          } else {
            if (!isObjectEmpty(filterParams)) {
              params.filters = JSON.stringify({
                $and: [{ "flags.name": { $ne: "bin" } }, filterParams],
              });
            } else {
              params.filters = JSON.stringify({ "flags.name": { $ne: "bin" } });
            }
          }

          if (queryParams.parentId) {
            params.parentId = queryParams.parentId;
          }
          const returnData = await ApiService.getSendData(params, signal);

          setMyFiles(returnData.filter((file) => file.parentId === undefined));

          if (!showListView) {
            let getSendDataCount;
            if (!ApiService.production) {
              getSendDataCount = await ApiService.getSendDataCount({
                filters: JSON.stringify({
                  $and: [
                    { "flags.name": "abendum" },
                    { "flags.name": { $ne: "bin" } },
                  ],
                }),
              });
            } else {
              getSendDataCount = await ApiService.getSendDataCount({
                filters: JSON.stringify({ "flags.name": { $ne: "bin" } }),
              });
            }

            setSendDataCount(getSendDataCount.count);

            const total = Math.ceil(getSendDataCount.count / pageSize);
            setTotalPages(total);
          }
        } catch (e) {
          console.error(e);
          setSnackbarMessage(e.message);
          setVariant("error");
          setOpenSnackbar(true);
        }
      };
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getQueryParams, currentPage, pageSize, sortBy, filterParams, showListView]
  );

  const getIcon = (fileType) => {
    if (fileType === "text/plain") {
      return <i className="far fa-file"></i>;
    } else if (fileType === "text/csv") {
      return <i className="fas fa-file-csv"></i>;
    } else if (fileType === "text/html") {
      return <i className="far fa-file-code"></i>;
    } else if (fileType.startsWith("image")) {
      return <i className="far fa-file-image"></i>;
    } else if (fileType === "application/pdf") {
      return <i className="far fa-file-pdf"></i>;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/vnd.oasis.opendocument.text"
    ) {
      return <i className="far fa-file-word"></i>;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return <i className="far fa-file-powerpoint"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="far fa-file-excel"></i>;
    } else if (
      fileType === "application/zip" ||
      fileType === "application/x-7z-compressed" ||
      fileType === "application/vnd.rar"
    ) {
      return <i className="far fa-file-archive"></i>;
    } else {
      return <i className="far fa-file-alt"></i>;
    }
  };

  // pagination
  const handleChangePage = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleClick = (newPageSize) => {
    const total = Math.ceil(sendDataCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(currentPage / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * currentPage);
    }
    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };

  const prepareDataForTable = (myFiles) => {
    const checkConfirmed = (signatures) => {
      if (signatures && signatures.length > 1) {
        const signSet = new Set();
        signatures.map((sig) => signSet.add(sig.sender));
        if (signSet.size >= 2) {
          return t("confirmed");
        } else {
          return t("not-confirmed");
        }
      } else {
        return t("not-confirmed");
      }
    };

    // const setDocumentType = (docType) => {
    //   if (docType === "Outbound invoice") {
    //     return t("sent-invoice");
    //   } else if (docType === "Inbound invoice") {
    //     return t("received-invoice");
    //   } else if (docType === "Other") {
    //     return t("other");
    //   }
    // };

    return myFiles.map((file) => {
      const queryParameters = new URLSearchParams({
        data_id: file.data_id,
        sender: file.sender,
        receiver: file.receiver,
        hash: file.hash,
      }).toString();
      return {
        title: file.title,
        createdAt: formatDate(file.createdAt),
        organizationNumber: file?.metadata?.find(
          (data) => data.name === "Customer organization number"
        )?.metadata,
        invoiceNumber: file?.metadata?.find(
          (data) => data.name === "Invoice number"
        )?.metadata,
        documentType: file?.metadata?.find(
          (data) => data.name === "Document Type"
        )?.metadata,
        confirmedStatus: checkConfirmed(file?.signatures),
        actions: (
          <>
            <Button
              onClick={() =>
                props.history.push(
                  `/admin/documents/details?${queryParameters}`
                )
              }
              color="rose"
            >
              {t("view")}
            </Button>
            <Tooltip title={t("move-to-bin")}>
              <IconButton
                aria-label="move-to-bin"
                onClick={() =>
                  handleMoveToBin(
                    file.data_id,
                    file.sender,
                    file.receiver,
                    file.hash
                  )
                }
                style={{ fontSize: "1em" }}
              >
                <ClearIcon
                  style={{
                    color: "#a5abbd",
                    fontSize: "1em",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
    });
  };

  React.useEffect(() => {
    if (showFilterSort === false) {
      setFilters("");
      setFilterParams({});
    }
  }, [showFilterSort]);

  React.useEffect(() => {
    let isMounted = true;
    const apiOperations = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      setLoading(true);
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, false));
      } catch (e) {
        console.error(e);
        setSnackbarMessage(e.message);
        setVariant("error");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [handleRefresh, showListView]);

  return (
    <>
      {loading && <Loading />}
      {!loading &&
      myFiles.length === 0 &&
      isObjectEmpty(filterParams) &&
      !isObjectEmpty(userProfile) &&
      userProfile.validatedDocuments &&
      userProfile.validatedEmail ? (
        <>
          <Card pricing plain>
            <CardBody pricing>
              <div className={classes.icon}>
                <BackupIcon className={classes.iconRose} />
              </div>
              <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                {t("no-documents-uploaded")}
              </h5>
              <p className={classes.cardDescription}>
                {t("start-uploading-documents")}
              </p>
            </CardBody>
          </Card>
        </>
      ) : (
        <GridContainer>
          {!loading &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail && (
              <FilterSortView
                handleClick={handleClick}
                showListView={showListView}
                pageSize={pageSize}
                handleShowListView={handleShowListView}
                sortBy={sortBy}
                handleSimple={handleSimple}
                handleSetSearchFilters={handleSetSearchFilters}
                filters={filters}
                handleSearchTitle={handleSearchTitle}
                showFilterSort={showFilterSort}
                handleShowFilterSort={handleShowFilterSort}
              />
            )}
          {!loading &&
            myFiles.length === 0 &&
            !isObjectEmpty(userProfile) &&
            !isObjectEmpty(filterParams) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            !showListView && (
              <Card pricing plain>
                <CardBody pricing>
                  <div className={classes.icon}></div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    {t("sorry-we-couldnt-find-file")}
                  </h5>
                  <p className={classes.cardDescription}>
                    {t("try-a-new-search-or-clear-filters")}
                  </p>
                </CardBody>
              </Card>
            )}
          {!loading &&
            !showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.map((file) => {
              const queryParameters = new URLSearchParams({
                data_id: file.data_id,
                sender: file.sender,
                receiver: file.receiver,
                hash: file.hash,
              }).toString();
              return (
                <GridItem xs={12} sm={6} md={4} lg={3} key={file.data_id}>
                  <Card pricing>
                    <CardBody pricing>
                      <div
                        className={classes.cardCategory}
                        style={{ textAlign: "right", marginTop: "-10px" }}
                      >
                        <Tooltip title={t("move-to-bin")}>
                          <IconButton
                            aria-label="move-to-bin"
                            onClick={() =>
                              handleMoveToBin(
                                file.data_id,
                                file.sender,
                                file.receiver,
                                file.hash
                              )
                            }
                            style={{ fontSize: "1em" }}
                          >
                            <ClearIcon
                              style={{ color: "#a5abbd", fontSize: "1em" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div className={classes.icon}>
                        <div className={classes.iconRose}>
                          {getIcon(file.dataType)}
                        </div>
                      </div>
                      <h6
                        className={`${classes.cardTitle} ${classes.marginTop30}`}
                      >
                        {formatDate(file.createdAt)}
                      </h6>
                      <h5 className={`${classes.cardTitle} `}>{file.title}</h5>
                      <p className={classes.cardDescription}></p>
                      <Link to={`/admin/documents/details?${queryParameters}`}>
                        <Button color="rose">{t("view")}</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
          {!loading &&
            showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.length > 0 && (
              <>
                <GridItem xs={12}>
                  <Card>
                    <CardBody>
                      <ReactTable
                        data={prepareDataForTable(myFiles)}
                        sortable={true}
                        filterable={true}
                        columns={[
                          {
                            Header: t("title"),
                            accessor: "title",
                          },
                          {
                            Header: t("created-at"),
                            accessor: "createdAt",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("organization-number"),
                            accessor: "organizationNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("invoice-number"),
                            accessor: "invoiceNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("document-type"),
                            accessor: "documentType",
                          },
                          {
                            Header: t("confirmed-status"),
                            accessor: "confirmedStatus",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                        ]}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        defaultPageSize={10}
                        nextText={t("next")}
                        previousText={t("previous")}
                        loadingText={t("loading")}
                        noDataText={t("no-data")}
                        pageText={t("page")}
                        ofText={t("of")}
                        rowsText={t("rows")}
                      />
                    </CardBody>
                    {/* <CardBody>
                      <Table
                        hover
                        tableHeaderColor="warning"
                        tableHead={["Title", "Created at", "Type", ""]}
                        tableData={myFiles.map((file) => {
                          const queryParameters = new URLSearchParams({
                            data_id: file.data_id,
                            sender: file.sender,
                            receiver: file.receiver,
                            hash: file.hash,
                          }).toString();
                          return [
                            file.title,
                            formatDate(file.createdAt),
                            file.dataType,
                            <>
                              <Button
                                onClick={() =>
                                  props.history.push(
                                    `/admin/documents/details?${queryParameters}`
                                  )
                                }
                                color="rose"
                              >
                                View
                              </Button>
                              <Tooltip title="Move to bin">
                                <IconButton
                                  aria-label="move-to-bin"
                                  onClick={() =>
                                    handleMoveToBin(
                                      file.data_id,
                                      file.sender,
                                      file.receiver,
                                      file.hash
                                    )
                                  }
                                  style={{ fontSize: "1em" }}
                                >
                                  <ClearIcon
                                    style={{
                                      color: "#a5abbd",
                                      fontSize: "1em",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>,
                          ];
                        })}
                        customCellClasses={[
                          classes.left,
                          classes.left,
                          classes.left,
                          classes.right,
                        ]}
                        customClassesForCells={[0, 1, 2, 3]}
                        customHeadCellClasses={[
                          classes.left,
                          classes.left,
                          classes.left,
                          classes.right,
                        ]}
                        customHeadClassesForCells={[0, 1, 2, 3]}
                      />
                    </CardBody> */}
                  </Card>
                </GridItem>
              </>
            )}
          <GridItem xs={12}>
            {!loading &&
              isObjectEmpty(filterParams) &&
              !isObjectEmpty(userProfile) &&
              !showListView &&
              userProfile.validatedDocuments &&
              userProfile.validatedEmail && (
                <Pagination
                  size="small"
                  count={totalPages}
                  color="secondary"
                  page={currentPage}
                  onChange={(event, page) => handleChangePage(page)}
                  className={classes2.root}
                />
              )}
          </GridItem>
        </GridContainer>
      )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  {!userProfile.requestDocumentsValidation ? (
                    <SupervisedUserCircleIcon className={classes.iconPrimary} />
                  ) : (
                    <HourglassEmptyIcon className={classes.iconPrimary} />
                  )}
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {!userProfile.requestDocumentsValidation
                    ? t("in-order-to-proceed")
                    : t("waiting-for-document-validation")}
                </h5>
                {!userProfile.requestDocumentsValidation ? (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail && t("validate-email")}
                    {!userProfile.validatedEmail &&
                      (!userProfile.validatedDocuments ? t("and") : ".")}
                    {!userProfile.validatedDocuments &&
                      t("request-a-document-validation")}
                  </p>
                ) : (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail &&
                      t("please-validate-your-email")}
                  </p>
                )}
                <Link to="/admin/user-page" style={{ color: "#D7A31A" }}>
                  {t("go-to-user-profile")}
                </Link>
              </CardBody>
            </Card>
          </>
        )}
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={snackbarMessage}
      />
    </>
  );
};

export default Documents;
