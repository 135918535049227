import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

/*import login from "assets/img/ugur-akdemir-FABH5NJEMGM-unsplash.jpg";
import login from "assets/img/rene-bohmer-YeUVDKZWSZ4-unsplash.jpg";
import register from "assets/img/thr3-eyes-MlpyeuKs8GY-unsplash.jpg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/sharon-mccutcheon-62vi3TG5EDg-unsplash.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import forgotPassword from "assets/img/josh-withers-yZGwrrVCjqk-unsplash.jpg";
*/
// components
import LoginPage from "../views/Pages/LoginPage.js";
import RegisterPage from "../views/Pages/RegisterPage.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ResetPasswordEmail from "views/Pages/ResetPasswordEmail.js";
import ValidateEmail from "views/Pages/ValidateEmail.js";
import RedirectPage from "views/Pages/RedirectPage";
import ResetPassword from "views/Pages/ResetPassword.js";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  /*const getBgImage = () => {
      if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
        return register;
      } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
        return login;
      } else if (window.location.pathname.indexOf("/auth/reset-password-email") !== -1) {
        return forgotPassword;
      } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
        return pricing;
      } else if (
        window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
      ) {
        return lock;
      } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
        return error;
      }
    };*/
  const getActiveRoute = (routes) => {
    let activeRoute = "Abendum";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        {/*<div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >*/}
        <div className={classes.fullPage} style={{ background: "#002144" }}>
          <Switch>
            <Route path="/auth/login-page" component={LoginPage} />
            <Route path="/auth/register-page" component={RegisterPage} />
            <Route path="/auth/error-page" component={ErrorPage} />
            <Route
              path="/auth/reset-password-email"
              component={ResetPasswordEmail}
            />
            <Route path="/auth/reset-password" component={ResetPassword} />
            <Route path="/auth/validate-email" component={ValidateEmail} />
            <Route path="/auth/redirect-page" component={RedirectPage} />
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer white hideSupport={true} />
        </div>
      </div>
    </div>
  );
}
