import React from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import Chip from "@material-ui/core/Chip";
import ListIcon from "@material-ui/icons/List";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

const useStylesSelect = makeStyles(extendedFormsStyle);
const useStyles2 = makeStyles((theme) => ({
  chip: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    "@media (max-width: 599px)": {
      justifyContent: "flex-start",
    },
  },
  chipColorPrimary: {
    color: "#dbb686",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  chipColorSecondary: {
    color: "#dbb686",
    fontWeight: "bold",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const FilterSortView = ({
  handleClick,
  showListView,
  pageSize,
  handleShowListView,
  sortBy,
  handleSimple,
  handleSetSearchFilters,
  filters,
  handleSearchTitle,
  showFilterSort,
  handleShowFilterSort,
}) => {
  const classes2 = useStyles2();
  const classesSelect = useStylesSelect();
  const { t } = useTranslation();

  return (
    <>
      <GridItem xs={11} sm={4} md={2}>
        {!showListView && (
          <Button
            onClick={handleShowFilterSort}
            color="primary"
            simple
            style={{ paddingLeft: "15px" }}
          >
            {showFilterSort ? t("x-clear-filters") : t("filter-and-sort")}
          </Button>
        )}
      </GridItem>
      <GridItem xs={1} sm={2} md={6}></GridItem>
      <GridItem xs={12} sm={6} md={4} className={classes2.chip}>
        {!showListView && (
          <>
            <Chip
              classes={{
                colorPrimary: classes2.chipColorPrimary,
                colorSecondary: classes2.chipColorSecondary,
              }}
              label="10"
              onClick={() => handleClick(10)}
              color={pageSize === 10 ? "secondary" : "primary"}
              style={{ marginRight: "5px" }}
            />
            <Chip
              classes={{
                colorPrimary: classes2.chipColorPrimary,
                colorSecondary: classes2.chipColorSecondary,
              }}
              label="20"
              color={pageSize === 20 ? "secondary" : "primary"}
              onClick={() => handleClick(20)}
              style={{ marginRight: "5px" }}
            />
            <Chip
              classes={{
                colorPrimary: classes2.chipColorPrimary,
                colorSecondary: classes2.chipColorSecondary,
              }}
              label="100"
              onClick={() => handleClick(100)}
              color={pageSize === 100 ? "secondary" : "primary"}
              style={{
                borderRight: "1px solid #dbb686",
                borderRadius: "0px",
              }}
            />
          </>
        )}

        {showListView ? (
          <Tooltip title={t("grid-view")}>
            <IconButton
              aria-label="grid-view"
              onClick={() => handleShowListView(false)}
            >
              <ViewComfyIcon style={{ color: "#dbb686" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("list-view")}>
            <IconButton
              aria-label="list-view"
              onClick={() => handleShowListView(true)}
            >
              <ListIcon style={{ color: "#dbb686" }} />
            </IconButton>
          </Tooltip>
        )}
      </GridItem>
      {showFilterSort && !showListView && (
        <GridContainer
          alignItems="center"
          style={{
            marginLeft: "15px",
            borderTop: "1px solid #e8d1b3",
          }}
        >
          <GridItem xs={10} sm={5} md={4} lg={3}>
            <FormControl fullWidth className={classesSelect.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classesSelect.selectLabel}
              >
                {t("sort-by")}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classesSelect.selectMenu,
                }}
                classes={{
                  select: classesSelect.select,
                }}
                value={sortBy}
                onChange={handleSimple}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classesSelect.selectMenuItem,
                  }}
                >
                  {t("sort-by")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesSelect.selectMenuItem,
                    selected: classesSelect.selectMenuItemSelected,
                  }}
                  value="title:asc"
                >
                  {t("title-asc")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesSelect.selectMenuItem,
                    selected: classesSelect.selectMenuItemSelected,
                  }}
                  value="title:desc"
                >
                  {t("title-desc")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesSelect.selectMenuItem,
                    selected: classesSelect.selectMenuItemSelected,
                  }}
                  value="createdAt:asc"
                >
                  {t("created-at-asc")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classesSelect.selectMenuItem,
                    selected: classesSelect.selectMenuItemSelected,
                  }}
                  value="createdAt:desc"
                >
                  {t("created-at-desc")}
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={8} sm={5} md={6} lg={8}>
            <CustomInput
              labelText={t("search-by-title")}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  handleSetSearchFilters(e.target.value);
                },
                value: filters,
              }}
            />
          </GridItem>{" "}
          <GridItem xs={4} sm={2} md={2} lg={1}>
            <Button color="primary" onClick={handleSearchTitle}>
              {t("search")}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

export default FilterSortView;
