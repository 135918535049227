import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import BackupIcon from "@material-ui/icons/Backup";
// import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Radio from "@material-ui/core/Radio";
import ModalComponent from "components/ModalComponent";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ApiService from "api/ApiService.js";
import SnackBarComponent from "components/SnackBarComponent.js";
import UploadStepper from "components/UploadStepper.js";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { Link } from "react-router-dom";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const New = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [containerTitle, setContainerTitle] = React.useState("");

  const [showStepper, setShowStepper] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [userProfile, setUserProfile] = React.useState({});

  // dialog
  //   const handleOpenDialog = () => {
  //     setOpenDialog(true);
  //   };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />
      </div>
    );
  };

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);

      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setOpenSnackbar(true);
  };

  const showErrorModalMessage = (messageContent) => {
    setErrorModalMessage(messageContent);
    setOpenSnackbar(true);
  };

  const handleAddDocumentContainer = async (event) => {
    event.preventDefault();

    const title = containerTitle;
    const validTitle = title.length > 0;
    const message = title;
    const validMessage = message.length > 0;
    const ttl = "-1";
    const validTTL = !(ttl === "-1");
    const checksPassed = validTitle && validMessage && validTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validTitle) {
        messageContent.push(t('invalid-title'));
      }
      if (!validMessage) {
        messageContent.push(t('invalid-message'));
      }
      if (!validTTL) {
        messageContent.push(t('invalid-eol'));
      }
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      title,
      message,
      access: "PRIVATE",
      endOfLife: ttl,
    };

    try {
      await ApiService.addDocumentContainer(params);
    } catch (e) {
      console.error(e);
      showErrorModalMessage(e.message);
    }
    showSuccessModalMessage(t('document-container-successfully-processed'));

    setOpenDialog(false);
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleShowStepper = () => {
    setShowStepper(true);
  };

  const handleFinishButton = () => {
    props.history.push("/admin/documents");
  };

  const handleCancelUpload = () => {
    setShowStepper(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const apiOperations = async () => {
      setLoading(true);
      try {
        const userProfile = await ApiService.loginRequired(undefined, false);
        setUserProfile(userProfile);
      } catch (e) {
        console.error(e);
        showErrorModalMessage(e.message);
      } finally {
        setLoading(false);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      setLoading(true);
    };
  }, []);

  return (
    <>
      {loading && <Loading />}

      {!loading &&
        !isObjectEmpty(userProfile) &&
        userProfile.validatedEmail &&
        userProfile.validatedDocuments &&
        showStepper ? (
        <UploadStepper
          handleFinishButton={handleFinishButton}
          handleCancelUpload={handleCancelUpload}
        />
      ) : (
        !loading &&
        !isObjectEmpty(userProfile) &&
        userProfile.validatedEmail &&
        userProfile.validatedDocuments && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}></GridItem>
            <GridItem xs={6} sm={3} md={3}>
              <Card pricing raised>
                <CardBody pricing>
                  <div className={classes.icon}>
                    <BackupIcon className={classes.iconRose} />
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    {t('document-upload')}
                  </h5>
                  <p className={classes.cardDescription}></p>
                  <Button color="rose" onClick={handleShowStepper}>
                    {t('upload')}
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridItem xs={6} sm={3} md={3}>
              <Card pricing raised>
                <CardBody pricing>
                  <div className={classes.icon}>
                    <CreateNewFolderIcon className={classes.iconPrimary} />
                  </div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    Document Container
                  </h5>
                  <p className={classes.cardDescription}></p>
                  <Button color="primary" onClick={handleOpenDialog}>
                    Create
                  </Button>
                </CardBody>
              </Card>
            </GridItem> */}
          </GridContainer>
        )
      )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  {!userProfile.requestDocumentsValidation ? (
                    <SupervisedUserCircleIcon className={classes.iconPrimary} />
                  ) : (
                    <HourglassEmptyIcon className={classes.iconPrimary} />
                  )}
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {!userProfile.requestDocumentsValidation
                    ? t('in-order-to-proceed')
                    : t('waiting-for-document-validation')}
                </h5>
                {!userProfile.requestDocumentsValidation ? (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail && t('validate-email')}
                    {!userProfile.validatedEmail &&
                      (!userProfile.validatedDocuments ? t("and") : ".")}
                    {!userProfile.validatedDocuments &&
                      t('request-a-document-validation')}
                  </p>
                ) : (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail &&
                      t('please-validate-your-email')}
                  </p>
                )}
                <Link to="/admin/user-page" style={{ color: "#D7A31A" }}>
                  {t('go-to-user-profile')}
                </Link>
              </CardBody>
            </Card>
          </>
        )}

      <ModalComponent
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogTitle={t('create-new-document-container')}
        dialogContent={
          <>
            <form>
              <GridContainer alignItems="baseline">
                <GridItem xs={12} sm={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('title')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    id="help-text"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: containerTitle || "",
                      onChange: (e) => {
                        setContainerTitle(e.target.value);
                      },
                    }}
                    helpText="A block of help text that breaks onto a new line."
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      classes.labelHorizontal +
                      " " +
                      classes.labelHorizontalRadioCheckbox
                    }
                  >
                    {t('access-type')}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={true}
                          value="PRIVATE"
                          disabled
                          name="Access type"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="PRIVATE"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </>
        }
        maxWidth="sm"
        dialogActions={
          <React.Fragment>
            <Button simple onClick={handleCloseDialog} color="rose">
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDocumentContainer}
            >
              {t('yes')}
            </Button>
          </React.Fragment>
        }
      />

      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={successModalMessage.length > 0 ? "success" : "error"}
        message={
          successModalMessage.length > 0
            ? successModalMessage
            : errorModalMessage
        }
      />
    </>
  );
};

export default New;
