import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import extendedTablesStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ApiService from "api/ApiService";
import AddMetadataInputs from "../../Pages/AddMetadataInputs";
import moment from "moment";

export const isObjectEmpty = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      colorSecondary: {
        color: "#4b679d",
        "&$checked": {
          color: "#4b679d",
        },
        "&&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...extendedTablesStyles,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      inboundInvoice: false,
      outboundInvoice: false,
      bankStatement: false,
      other: false,
      customValue: "",
      metadata: [],
      metadataInputs: ApiService.initState,
    };
  }

  async getPackage() {
    const documentDetails = await ApiService.getPackageDetails(
      this.props.queryParams
    );
    return documentDetails;
  }

  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSetState = (value, key) => {
    if (key === "Invoice date") {
      this.setState({
        metadataInputs: {
          ...this.state.metadataInputs,
          [key]: {
            ...this.state.metadataInputs[key],
            value: value !== null ? moment(value).toISOString() : "",
          },
        },
      });
    } else {
      this.setState({
        metadataInputs: {
          ...this.state.metadataInputs,
          [key]: { ...this.state.metadataInputs[key], value: value },
        },
      });
    }
  };

  getName = (name) => {
    if (name === "inboundInvoice") {
      return "Inbound invoice";
    } else if (name === "outboundInvoice") {
      return "Outbound invoice";
    } else if (name === "bankStatement") {
      return "Bank statement";
    } else {
      return "Other";
    }
  };

  handleChange = (name) => async (event) => {
    this.setState({
      inboundInvoice: false,
      outboundInvoice: false,
      bankStatement: false,
      other: false,
    });

    this.setState({ [name]: event.target.checked });

    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      let parentId;
      let parentType;

      //if (this.props.containerId) {
      //    parentId = this.props.containerId;
      //    parentType = "CONTAINER";
      //} else {
      const packageDetails = await this.getPackage();
      parentId = packageDetails._id;
      parentType = "PACKAGE";
      //}
      await ApiService.addMetadata(
        {
          parentId,
          parentType,
          name: "Document Type",
          type: ApiService.metadataTypes.string,
          metadata: this.getName(name),
        },
        signal
      );
    } catch (e) {
      console.error(e);
    }
  };

  isValidated() {
    return true;
  }

  handleSimple = (event) => {
    this.setState({ simpleSelect: event.target.value });
  };

  // select add metadata
  handleAddData = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const packageDetails = await this.getPackage();

    for (const property in this.state.metadataInputs) {
      const found = packageDetails.metadata.filter(
        (data) => data.name === property
      );
      if (
        (found.length > 0 &&
          this.state.metadataInputs[property].value.length > 0 &&
          found[0].metadata !== this.state.metadataInputs[property].value) ||
        (found.length === 0 &&
          this.state.metadataInputs[property].value.length > 0)
      ) {
        try {
          await ApiService.addMetadata(
            {
              parentId: packageDetails._id,
              parentType: "PACKAGE",
              name: property,
              type: this.state.metadataInputs[property].type,
              metadata:
                this.state.metadataInputs[property].type ===
                ApiService.metadataTypes.json
                  ? JSON.parse(this.state.metadataInputs[property].value)
                  : this.state.metadataInputs[property].value,
            },
            signal
          );
        } catch (e) {
          console.error(e);
        }
      }
      if (
        found.length > 0 &&
        this.state.metadataInputs[property].value.length === 0 &&
        found[0].metadata !== this.state.metadataInputs[property].value
      ) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: packageDetails._id,
          parentType: "PACKAGE",
          metadataId: found[0]._id,
        };
        try {
          await ApiService.deleteMetadata(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  // handleAddData = async () => {
  //     if (this.state.customValue.length === 0) {
  //         this.setState({ errorMessage: "Field empty" });
  //         // show Snackbar
  //         return
  //     }
  //     let type;
  //     switch (this.state.simpleSelect) {
  //         case 2:
  //         case 3:
  //         case 4:
  //         case 5:
  //         case 6:
  //         case 9:
  //             // json
  //             type = ApiService.metadataTypes.string
  //             break;
  //         case 7:
  //             // xml
  //             type = ApiService.metadataTypes.xml;
  //             break;
  //         case 8:
  //             type = ApiService.metadataTypes.json;
  //             break;
  //         default:
  //             type = ApiService.metadataTypes.string;
  //     }

  //     const abortController = new AbortController();
  //     const signal = abortController.signal;

  //     try {
  //         let parentId;
  //         let parentType;

  //         //if (this.props.containerId) {
  //         //    parentId = this.props.containerId;
  //         //    parentType = "CONTAINER";
  //         //} else {
  //             const packageDetails = await this.getPackage();
  //             parentId = packageDetails._id;
  //             parentType = "PACKAGE";
  //         //}

  //         await ApiService.addMetadata(
  //             {
  //                 parentId,
  //                 parentType,
  //                 name: selectNames[this.state.simpleSelect - 2],
  //                 type: type,
  //                 metadata: type === ApiService.metadataTypes.json ? JSON.parse(this.state.customValue) : this.state.customValue
  //             },
  //             signal
  //         );
  //         this.setState({ customValue: "" });

  //         const allData = await this.getPackage();
  //         this.setState({ metadata: allData.metadata })
  //     } catch (e) {
  //         console.error(e)
  //     }
  // };

  // handleDeleteData = async (e, parentId, metadataId, parentType) => {
  //     const abortController = new AbortController();
  //     const signal = abortController.signal;
  //     const params = {
  //         parentId,
  //         parentType,
  //         metadataId
  //     };
  //     try {
  //         await ApiService.deleteMetadata(params, signal);
  //         const allData = await this.getPackage();
  //         this.setState({ metadata: allData.metadata })
  //     } catch (e) {
  //         // put snackbar
  //         console.error(e)
  //     }
  // };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>Select document type (optional)</h4>
        <GridContainer justify="center">
          <ThemeProvider theme={theme}>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <div className={classes.choiche}>
                    <Radio
                      tabIndex={-1}
                      checked={this.state.inboundInvoice === true}
                      onClick={this.handleChange("inboundInvoice")}
                      checkedIcon={
                        <i
                          className={
                            "fas fa-arrow-down " + classes.iconCheckboxIcon
                          }
                          style={{ color: "#4b679d" }}
                        />
                      }
                      icon={
                        <i
                          className={
                            "fas fa-arrow-down " + classes.iconCheckboxIcon
                          }
                        />
                      }
                      classes={{
                        checked: classes.iconCheckboxChecked,
                        root: classes.iconCheckbox,
                      }}
                    />
                    <h6>Inbound invoice</h6>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <div className={classes.choiche}>
                    <Radio
                      checked={this.state.outboundInvoice === true}
                      tabIndex={-1}
                      onClick={this.handleChange("outboundInvoice")}
                      checkedIcon={
                        <i
                          className={
                            "fas fa-arrow-up " + classes.iconCheckboxIcon
                          }
                          style={{ color: "#4b679d" }}
                        />
                      }
                      icon={
                        <i
                          className={
                            "fas fa-arrow-up " + classes.iconCheckboxIcon
                          }
                        />
                      }
                      classes={{
                        checked: classes.iconCheckboxChecked,
                        root: classes.iconCheckbox,
                      }}
                    />
                    <h6>Outbound invoice</h6>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <div className={classes.choiche}>
                    <Radio
                      checked={this.state.bankStatement === true}
                      tabIndex={-1}
                      onClick={this.handleChange("bankStatement")}
                      checkedIcon={
                        <i
                          className={
                            "fas fa-university " + classes.iconCheckboxIcon
                          }
                          style={{ color: "#4b679d" }}
                        />
                      }
                      icon={
                        <i
                          className={
                            "fas fa-university " + classes.iconCheckboxIcon
                          }
                        />
                      }
                      classes={{
                        checked: classes.iconCheckboxChecked,
                        root: classes.iconCheckbox,
                      }}
                    />
                    <h6>Bank statement</h6>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <div className={classes.choiche}>
                    <Radio
                      checked={this.state.other === true}
                      tabIndex={-1}
                      onClick={this.handleChange("other")}
                      checkedIcon={
                        <i
                          className={
                            "fas fa-file-alt " + classes.iconCheckboxIcon
                          }
                          style={{ color: "#4b679d" }}
                        />
                      }
                      icon={
                        <i
                          className={
                            "fas fa-file-alt " + classes.iconCheckboxIcon
                          }
                        />
                      }
                      classes={{
                        checked: classes.iconCheckboxChecked,
                        root: classes.iconCheckbox,
                      }}
                    />
                    <h6>Other</h6>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </ThemeProvider>
        </GridContainer>

        <GridContainer
          justify="center"
          style={{ padding: "20px 15px", marginTop: "20px" }}
        >
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Additional document information
            </h4>
          </GridItem>
          <AddMetadataInputs
            currentState={this.state.metadataInputs}
            handleSetState={this.handleSetState}
            handleAddData={this.handleAddData}
          />
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
