import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "components/CustomButtons/Button.js";
import CheckIcon from "@material-ui/icons/Check";
import ApiService from "api/ApiService.js";
import ClipLoader from "react-spinners/ClipLoader";
import SnackBarComponent from "components/SnackBarComponent.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastname: "",
      lastnameState: "",
      fileTitle: "",
      selectedFile: null,
      loading: false,
      showSuccess: false,
      successMessage: "",
      errorMessage: "",
      openSnackbar: false,
    };
  }
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (this.state.lastnameState === "success" && this.state.showSuccess) {
      return true;
    } else {
      if (this.state.lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (this.state.showSuccess === false) {
        this.setState({
          openSnackbar: true,
          errorMessage: "You need to upload a document in order to proceed!",
        });
      }
    }
    return false;
  }

  // snackbar
  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  handleFileUpload = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });
    const fileTitle = this.state.fileTitle.trim();
    const selectedFile = this.state.selectedFile;
    const fileTTL = "-1";

    const params = {
      file: selectedFile,
      shareable: "true",
      ttl: fileTTL.toString(),
      encoding: ApiService.storageFormat,
      title: fileTitle,
      message: "File upload",
    };
    if (this.state.superuser) {
      params.userId = this.state.userId;
    }
    if (this.props.containerId) {
      params.parentId = this.props.containerId;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    this.setState({ abortController });

    try {
      const keyParams = {};
      if (this.state.superuser) {
        keyParams.userId = this.state.userId;
      }
      const { address } = await ApiService.getPublicKey(keyParams, signal);
      params.receivers = JSON.stringify([address]);
    } catch (e) {
      this.setState({
        errorMessage: "Error fetching user data",
        openSnackbar: true,
      });
      return;
    }

    // Upload file to server
    let responseData;
    try {
      responseData = await ApiService.uploadFile(params, signal);
    } catch (e) {
      this.setState({
        errorMessage: e.message,
        openSnackbar: true,
        loading: false,
      });
      return;
    }
    const jobId = responseData.jobId;
    this.setState({
      jobId,
      progress: responseData.progress,
      progressRuntime: responseData.runtime,
    });
    try {
      const jobParams = { jobId };
      if (this.state.superuser) {
        jobParams.userId = this.state.userId;
      }
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        this.setState({
          progress: responseData.progress,
          progressRuntime: responseData.runtime,
        });
        if (responseData.status === ApiService.jobStatus.failed) {
          this.setState({
            loading: false,
            errorMessage: "Error uploading file",
            openSnackbar: true,
          });
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          this.setState({ loading: false, showSuccess: true });

          const data = responseData.details.blockchainTransactions[0];
          const params = {
            data_id: data.data_id,
            hash: data.rawHash,
            sender: data.senderAddress,
            receiver: data.receiverAddress,
          };
          this.props.handleAction(params);

          return;
        }
      }
      if (signal.aborted) {
        this.setState({
          errorMessage: "Operation aborted",
          openSnackbar: true,
        });
        return;
      }
    } catch (e) {
      this.setState({
        errorMessage: "Error uploading file",
        openSnackbar: true,
      });
      return;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer justify="center" alignItems="baseline">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Select a document and upload it
            </h4>
          </GridItem>
          <GridItem xs={12} sm={3}></GridItem>
          <GridItem xs={12} sm={6}>
            <div
              className="picture-container"
              style={{ border: "1px dashed grey", borderRadius: "10px" }}
            >
              <div className="picture1">
                <CloudUploadIcon
                  style={{
                    width: "2.5em",
                    height: "2.5em",
                    color: "#4b679d",
                    paddingTop: "1em",
                  }}
                />
                <label
                  htmlFor="files"
                  className="btn"
                  style={{ display: "block" }}
                >
                  Select File
                </label>
                <input
                  type="file"
                  id="files"
                  ref={this.fileInput}
                  disabled={this.state.loading}
                  onChange={(e) => {
                    this.setState({
                      loading: false,
                      showSuccess: false,
                      openSnackbar: false,
                      errorMessage: "",
                      successMessage: "",
                    });
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                      this.setState({
                        fileTitle: selectedFile.name,
                        selectedFile,
                        lastnameState: "success",
                      });
                    }
                  }}
                />
              </div>

              <h6 className="description">
                {this.state.selectedFile && this.state.selectedFile.name}
              </h6>
              <h6 className="description">
                {this.state.selectedFile &&
                  `Size: ${this.state.selectedFile.size}`}
              </h6>
            </div>
          </GridItem>
          <GridItem xs={12} sm={3}></GridItem>

          <GridItem xs={12} sm={3}></GridItem>
          <GridItem xs={8} sm={4}>
            <CustomInput
              success={this.state.lastnameState === "success"}
              error={this.state.lastnameState === "error"}
              labelText={<span>Title</span>}
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: this.state.fileTitle,
                disabled: this.state.loading,
                onChange: (e) => {
                  this.change(e, "lastname", "length", 3);
                  this.setState({
                    fileTitle: e.target.value,
                  });
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <span></span>
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={8} sm={2} md={2}>
            {this.state.loading ? (
              <Button color="primary" fullWidth>
                <span className={classes.icons}>
                  <ClipLoader
                    size={18}
                    color={"#fff"}
                    loading={this.state.loading}
                  />
                </span>
                Uploading
              </Button>
            ) : this.state.showSuccess ? (
              <Button
                color="primary"
                fullWidth
                disabled
                onClick={this.handleFileUpload}
              >
                <CheckIcon
                  className={classes.icons}
                  style={{ color: "#4B947A" }}
                />
                Uploaded
              </Button>
            ) : (
              <Button
                color="primary"
                fullWidth
                onClick={this.handleFileUpload}
                disabled={this.state.selectedFile === null}
              >
                <CloudUploadIcon className={classes.icons} /> Upload
              </Button>
            )}
          </GridItem>
          <GridItem xs={12} sm={3} md={3}></GridItem>
        </GridContainer>
        <SnackBarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          variant={this.state.successMessage.length > 0 ? "success" : "error"}
          message={
            this.state.successMessage.length > 0
              ? this.state.successMessage
              : this.state.errorMessage
          }
        />
      </>
    );
  }
}

export default withStyles(style)(Step1);
