import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>{t('page-not-found')}</h2>
          <h4 className={classes.description}>
            {t('ooooups-looks-like-you-got-lost')}
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
