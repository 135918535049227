import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Gavel from "@material-ui/icons/Gavel";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CustomNavPills from "components/NavPills/CustomNavPills.js";

import Info from "@material-ui/icons/Info";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button";
import SignaturesTable from "./SignaturesTable.js";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ApiService from "api/ApiService.js";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";

import GetAppIcon from "@material-ui/icons/GetApp";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from "@material-ui/core/Tooltip";
import ModalComponent from "components/ModalComponent.js";
import StorageIcon from "@material-ui/icons/Storage";
import AccessRightsTable from "./AccessRightsTable";
import ClipLoader from "react-spinners/ClipLoader";
import SnackBarComponent from "components/SnackBarComponent.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import QRCode from "react-qr-code";
import CheckIcon from "@material-ui/icons/Check";
import PDFViewer from "./PDFViewer";
import HashTimeline from "./HashTimeline";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2.js";
import AddMetadataInputs from "./AddMetadataInputs";
import moment from "moment";
import { Link } from "react-router-dom";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
  cardIconTitle: {
    marginTop: "35px",
  },
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
  textAlignRight: {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

const DocumentDetailsPage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = React.useState(ApiService.initState);

  const [documentDetails, setDocumentDetails] = React.useState({});
  const [documentQrCode, setDocumentQrCode] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [documentType, setDocumentType] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [deleting, setDeleting] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openBCModal, setOpenBCModal] = React.useState(false);

  const [openVerifyHashModal, setOpenVerifyHashModal] = React.useState(false);
  const [verificationData, setVerificationData] = React.useState([]);

  const [openSignModal, setOpenSignModal] = React.useState(false);

  const [
    openVerifySignatureModal,
    setOpenVerifySignatureModal,
  ] = React.useState(false);
  const [
    signatureVerificationData,
    setSignatureVerificationData,
  ] = React.useState([]);

  const [inputData, setInputData] = React.useState({});
  const [
    openDeleteSignatureModal,
    setOpenDeleteSignatureModal,
  ] = React.useState(false);
  const [deleteParams, setDeleteParams] = React.useState({});

  const [pdfFile, setPdfFile] = React.useState({ data: new Uint8Array(0) });
  const [pdfReport, setPdfReport] = React.useState({ data: new Uint8Array(0) });

  const [showPdfPreview, setShowPdfPreview] = React.useState(false);
  const [lookupUsers, setLookupUsers] = React.useState({});

  const [userProfile, setUserProfile] = React.useState({});

  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isMetadataEmpty, setIsMetadataEmpty] = React.useState(true);

  const isShared = props.history.location.pathname.startsWith(
    "/admin/shared-documents/"
  )
    ? true
    : false;

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  const handleCloseVerifySignatureModal = () => {
    setOpenVerifySignatureModal(false);
  };

  const handleOpenSignatureModal = async (
    signatureId,
    parentId,
    parentType,
    metadataHash,
    metadata
  ) => {
    setOpenVerifySignatureModal(true);
    setInputData({ hash: documentDetails.hash, metadataHash, metadata });

    try {
      const response = await ApiService.verifySignature({
        signatureId,
        parentId,
        parentType,
      });
      setSignatureVerificationData(response.verificationSteps);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const handleOpenDeleteSignatureModal = (
    signatureId,
    parentId,
    parentType
  ) => {
    setDeleteParams({ signatureId, parentId, parentType });
    setOpenDeleteSignatureModal(true);
  };

  const handleCloseDeleteSignatureModal = () => {
    setOpenDeleteSignatureModal(false);
  };

  const handleDeleteSignature = async () => {
    setOpenDeleteSignatureModal(false);
    try {
      await ApiService.deleteSignature(deleteParams);
      await handleRefresh();
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Retrieve package search parameters
  const query = new URLSearchParams(props.location.search);
  const packageParams = {
    data_id: query.get("data_id"),
    hash: query.get("hash"),
    sender: query.get("sender"),
    receiver: query.get("receiver"),
  };

  const queryParameters = new URLSearchParams({
    data_id: packageParams.data_id,
    sender: packageParams.sender,
    receiver: packageParams.receiver,
    hash: packageParams.hash,
  }).toString();

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      redirectPath: `/admin/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  // todo modify
  const handleRefresh = React.useCallback(
    async (returnData = undefined, signal = undefined) => {
      let documentData;
      const query = new URLSearchParams(props.location.search);
      const packageParams = {
        data_id: query.get("data_id"),
        hash: query.get("hash"),
        sender: query.get("sender"),
        receiver: query.get("receiver"),
      };

      const queryParameters = new URLSearchParams({
        data_id: packageParams.data_id,
        sender: packageParams.sender,
        receiver: packageParams.receiver,
        hash: packageParams.hash,
      }).toString();

      const getFrontendUrl = () => {
        const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
        url.search = new URLSearchParams({
          redirectPath: `/admin/documents/details?${queryParameters}`,
        });

        return url.toString();
      };
      if (!returnData) {
        try {
          documentData = await ApiService.getPackageDetails(
            packageParams,
            signal
          );
          const qrCode = await ApiService.getPackageQrCode(
            {
              frontendUrl: getFrontendUrl(),
              packageId: documentData._id,
            },
            signal
          );
          setDocumentQrCode(qrCode.qrCode);
        } catch (e) {
          console.error(e);
          props.history.push("/auth/error-page");
        }
      } else {
        documentData = returnData;
      }

      setDocumentDetails(documentData);

      if (documentData.dataType === "application/pdf") {
        setShowPdfPreview(true);
      }

      const metadata =
        documentData.metadata.length > 0 &&
        documentData.metadata.filter((data) => data.name === "Document Type");
      if (metadata.length > 0) {
        setDocumentType(
          metadata[0].metadata === "Inbound invoice"
            ? t("received-invoice")
            : t("sent-invoice")
        );
      } else {
        setDocumentType(t("other"));
      }
    },
    [props.location.search, props.history, t]
  );

  React.useEffect(() => {
    if (!isObjectEmpty(documentDetails)) {
      documentDetails.metadata
        .filter((data) => data.name !== "Document Type")
        .map((data) => {
          if (data.metadata.length > 0) {
            setIsMetadataEmpty(false);
          }
          setState((state) => ({
            ...state,
            [data.name]: { ...state[data.name], value: data.metadata },
          }));
          return null;
        });
    }
  }, [documentDetails]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && isObjectEmpty(lookupUsers)) {
      const getUsers = async () => {
        const responseData = await ApiService.getAllUsers({});
        const users = {};
        for (const entry of responseData) {
          users[entry.address] = entry.user.email;
        }
        setLookupUsers(users);
      };

      getUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [lookupUsers]);

  // qr code
  const handleOpenQrModal = () => {
    setOpenModal(true);
  };

  const handleCloseOrModal = () => {
    setOpenModal(false);
  };

  // delete modal
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  // delete file
  const handleDeleteFile = async () => {
    setOpenDeleteModal(false);

    setDeleting(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.deleteFile(
        {
          data_id: documentDetails.data_id,
          hash: documentDetails.hash,
          sender: documentDetails.sender,
          receiver: documentDetails.receiver,
        },
        signal
      );
    } catch (e) {
      console.error(e);
      setDeleting(false);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
      return;
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          setDeleting(false);
          setOpenSnackbar(true);
          setVariant("error");
          setSnackbarMessage(t("error-deleting-file"));
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setDeleting(false);
          props.history.push("/admin/documents");
        }
      }
      if (signal.aborted) {
        setDeleting(false);
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(t("operation-aborted"));
      }
    } catch (e) {
      setDeleting(false);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(t("error-deleting-file"));
    }
  };

  // select add metadata
  const handleAddData = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    for (const property in state) {
      const found = documentDetails.metadata.filter(
        (data) => data.name === property
      );
      if (
        (found.length > 0 &&
          state[property].value.length > 0 &&
          found[0].metadata !== state[property].value) ||
        (found.length === 0 && state[property].value.length > 0)
      ) {
        try {
          await ApiService.addMetadata(
            {
              parentId: documentDetails._id,
              parentType: "PACKAGE",
              name: property,
              type: state[property].type,
              metadata:
                state[property].type === ApiService.metadataTypes.json
                  ? JSON.parse(state[property].value)
                  : state[property].value,
            },
            signal
          );
          setOpenSnackbar(true);
          setVariant("success");
          setSnackbarMessage(t("successfully-added-data"));
          handleRefresh();
        } catch (e) {
          console.error(e);
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
      }
      if (
        found.length > 0 &&
        state[property].value.length === 0 &&
        found[0].metadata !== state[property].value
      ) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: documentDetails._id,
          parentType: "PACKAGE",
          metadataId: found[0]._id,
        };
        try {
          await ApiService.deleteMetadata(params, signal);
          handleRefresh();
        } catch (e) {
          console.error(e);
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
      }
    }
  };

  const handleSetState = (value, key) => {
    if (key === "Invoice date") {
      setState({
        ...state,
        [key]: {
          ...state[key],
          value: value !== null ? moment(value).toISOString() : "",
        },
      });
    } else {
      setState({ ...state, [key]: { ...state[key], value: value } });
    }
  };

  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleGiveAccessSendEmail = async (emailAddress, index) => {
    setLoading(true);
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData;
    try {
      returnData = await ApiService.addAccessRights(
        {
          parentId: documentDetails._id,
          parentType: "PACKAGE",
          type: "SIGN",
          email: emailAddress,
          endOfLife: "-1",
        },
        signal
      );
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErrorMessage(e.message);
      return;
    }

    const params = {
      parentId: documentDetails._id,
      parentType: "PACKAGE",
      message,
      accessRightsId: returnData.accessRights.filter(
        (user) => user.email === emailAddress
      )[0]._id,
      frontendUrl: getFrontendUrl(),
    };

    try {
      await ApiService.inviteAccessRights(params, signal);
      handleRefresh();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErrorMessage(e.message);
    }
    if (index + 1 === emails.length) {
      setLoading(false);
      setOpenAddModal(false);
    }
  };

  const handleDeleteAccess = async (e, parentId, id, parentType) => {
    // Deletion parameters
    const params = {
      parentId,
      parentType,
      accessRightsId: id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.deleteAccessRights(params, signal);
      setOpenSnackbar(true);
      setVariant("success");
      setSnackbarMessage(t("successfully-revoked-access-right"));
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
    await handleRefresh();
  };

  const handleOpenBCModal = () => {
    setOpenBCModal(true);
  };

  const handleCloseBCModal = () => {
    setOpenBCModal(false);
  };

  const handleOpenVerifyHashModal = async () => {
    setOpenVerifyHashModal(true);
    try {
      const packageVerificationData = await ApiService.getPackageSignature(
        packageParams
      );

      setVerificationData(packageVerificationData.verificationSteps);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const handleCloseVerifyHashModal = () => {
    setOpenVerifyHashModal(false);
  };

  const handleOpenSignModal = () => {
    setOpenSignModal(true);
  };

  const handleCloseSignModal = () => {
    setOpenSignModal(false);
  };

  const handleMoveToBin = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      data_id: documentDetails.data_id,
      sender: documentDetails.sender,
      receiver: documentDetails.receiver,
      hash: documentDetails.hash,
      flags: JSON.stringify([
        { name: "abendum", value: true },
        { name: "bin", value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      props.history.push("/admin/bin");
    } catch (e) {
      console.error(e);
      setSnackbarMessage(e.message);
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const handleSignDocument = async () => {
    setOpenSignModal(false);
    // Deletion parameters
    const params = {
      parentId: documentDetails._id,
      parentType: "PACKAGE",
      access: documentDetails.access,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setOpenSnackbar(true);
          setVariant("success");
          setSnackbarMessage(t("document-successfully-signed"));

          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
      }
    } catch (e) {
      console.error("Error downloading package");
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, signal)); //  todo
      } catch (e) {
        console.error(e);
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [handleRefresh]);

  const previewFile = {
    tabButton: t("preview"),
    tabIcon: DescriptionIcon,
    tabContent: (
      <GridContainer>
        <GridItem xs={12}>
          {pdfFile.data.length > 0 && <PDFViewer data={pdfFile} />}
        </GridItem>
      </GridContainer>
    ),
  };

  const previewReport = {
    tabButton: t("report"),
    tabIcon: AssignmentIcon,
    tabContent: (
      <GridContainer>
        <GridItem xs={12}>
          {pdfReport.data.length > 0 && <PDFViewer data={pdfReport} />}
        </GridItem>
      </GridContainer>
    ),
  };

  const handleDownloadDocument = () => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(
          packageParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handleDownloadFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
    };
    handlePrepareFileDownload();
  };

  const handleDownloadReport = () => {
    const handlePreviewFile = async (userId, parameters, signal, type) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(
        reportParams,
        signal
      );

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentDetails._id,
        parentType: "PACKAGE",
        frontendUrl: getFrontendUrl(),
        creatorName: "Abendum MVP",
        documentTitle: "Abendum MVP Report",
        reportTitle: t("report"),
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(
          reportParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
    };
    handlePrepareFileDownload();
  };

  const handleLoadPreviewFile = (type) => {
    if (type === "file") {
      if (pdfFile.data.length === 0) {
        const handlePreviewFile = async (userId, parameters, signal) => {
          const params = {
            jobId: parameters.jobId,
            sender: parameters.details.senderAddress,
            receiver: parameters.details.receiverAddress,
            data_id: parameters.details.data_id,
            hash: parameters.details.rawHash,
          };
          const responseData = await ApiService.downloadFile(params, signal);
          const fileBuffer = await responseData.arrayBuffer();
          const uint8View = new Uint8Array(fileBuffer);
          setPdfFile({ data: uint8View });
        };

        const handlePrepareFileDownload = async () => {
          // Initialize abort controller
          const abortController = new AbortController();
          const signal = abortController.signal;
          let responseData;
          try {
            responseData = await ApiService.prepareDownloadFile(
              packageParams,
              signal
            );
          } catch (e) {
            console.error("Error downloading package");
            setOpenSnackbar(true);
            setVariant("warning");
            setSnackbarMessage(e.message);
          }
          const jobId = responseData.jobId;
          try {
            const jobParams = { jobId };
            while (!signal.aborted && responseData.progress !== 100) {
              responseData = await ApiService.queryLatestJobStatus(
                jobParams,
                signal
              );
              if (responseData.status === ApiService.jobStatus.failed) {
                console.error("Error downloading package");
              }
              if (
                responseData.status === ApiService.jobStatus.done ||
                responseData.status === ApiService.jobStatus.failedBlockchain
              ) {
                await handlePreviewFile(undefined, responseData, signal);
              }
            }
            if (signal.aborted) {
              console.error("Operation aborted");
            }
          } catch (e) {
            console.error("Error downloading package");
            setOpenSnackbar(true);
            setVariant("warning");
            setSnackbarMessage(e.message);
          }
        };
        handlePrepareFileDownload();
      }
    } else {
      //if (pdfReport.data.length === 0) {

      const handlePreviewFile = async (userId, parameters, signal, type) => {
        const reportParams = {
          jobId: parameters.jobId,
        };
        const responseData = await ApiService.downloadReport(
          reportParams,
          signal
        );

        const fileBuffer = await responseData.arrayBuffer();
        const uint8View = new Uint8Array(fileBuffer);
        setPdfReport({ data: uint8View });
      };

      const handlePrepareFileDownload = async () => {
        // Initialize abort controller
        const abortController = new AbortController();
        const signal = abortController.signal;

        const reportParams = {
          parentId: documentDetails._id,
          parentType: "PACKAGE",
          frontendUrl: getFrontendUrl(),
          creatorName: "Abendum MVP",
          documentTitle: "Abendum MVP Report",
          reportTitle: t("report"),
        };
        let responseData;
        try {
          responseData = await ApiService.prepareDownloadReport(
            reportParams,
            signal
          );
        } catch (e) {
          console.error("Error downloading package");
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
        const jobId = responseData.jobId;
        try {
          const jobParams = { jobId };
          while (!signal.aborted && responseData.progress !== 100) {
            responseData = await ApiService.queryLatestJobStatus(
              jobParams,
              signal
            );
            if (responseData.status === ApiService.jobStatus.failed) {
              console.error("Error downloading package");
            }
            if (
              responseData.status === ApiService.jobStatus.done ||
              responseData.status === ApiService.jobStatus.failedBlockchain
            ) {
              await handlePreviewFile(undefined, responseData, signal);
            }
          }
          if (signal.aborted) {
            console.error("Operation aborted");
          }
        } catch (e) {
          console.error("Error downloading package");
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
      };
      handlePrepareFileDownload();
      //}
    }
  };

  const hadleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddEmailAddresses = (value) => {
    setEmails(value);
  };

  const handleMulitpleGiveAccess = async () => {
    setErrorMessage("");
    emails.map((email, index) => handleGiveAccessSendEmail(email, index));
  };

  React.useEffect(() => {
    if (errorMessage.length === 0) {
      setOpenAddModal(false);
      setLoading(false);
      //   setOpenSnackbar(true);
      setMessage("");
      //   setVariant("success");
      //   setSnackbarMessage("Successfully send invitation");
    }
  }, [errorMessage]);

  return (
    <>
      {isObjectEmpty(documentDetails) && <Loading />}
      {!isObjectEmpty(documentDetails) &&
        !isObjectEmpty(userProfile) &&
        userProfile.validatedDocuments &&
        userProfile.validatedEmail && (
          <>
            <Backdrop className={classes.backdrop} open={deleting}>
              <h2>{t("deleting-file")}</h2>
              <CircularProgress color="inherit" />
            </Backdrop>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <CustomNavPills
                  color="info"
                  alignCenter
                  tabs={[
                    {
                      tabButton: t("info"),
                      tabIcon: Info,
                      tabContent: (
                        <GridContainer>
                          <GridItem xs={12} lg={5}>
                            <Card>
                              <CardHeader color="lightBlue" icon>
                                <CardIcon color="lightBlue">
                                  <InfoIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  {t("document-info")}
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "10px" }}
                                >
                                  {documentDetails.title}
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {t("name")}
                                </h6>

                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "20px" }}
                                >
                                  {lookupUsers[documentDetails.sender]}
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {t("owner")}
                                </h6>

                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "20px" }}
                                >
                                  {
                                    documentDetails.metadata.find(
                                      (data) =>
                                        data.name ===
                                        "Customer organization number"
                                    )?.metadata
                                  }
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {isShared ? t("sender") : t("receiver")}
                                </h6>

                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "20px" }}
                                >
                                  {documentType}
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {t("type")}
                                </h6>

                                {/* <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Name
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentDetails.title}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Owner
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {lookupUsers[documentDetails.sender]}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Access
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentDetails.access}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Type
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentType}
                                  </GridItem>
                                </GridContainer> */}

                                <hr className={classes.footerLine} />
                                <div style={{ display: "inline" }}>
                                  {!isShared && (
                                    <>
                                      <Tooltip title={t("move-to-bin")}>
                                        <IconButton
                                          aria-label="move-to-bin"
                                          onClick={() => handleMoveToBin()}
                                          style={{ fontSize: "1em" }}
                                        >
                                          <ClearIcon
                                            style={{
                                              color: "#a5abbd",
                                              fontSize: "1em",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={t("delete")}>
                                        <IconButton
                                          aria-label="delete"
                                          className={classes.margin}
                                          style={{ color: "#A5ABBD" }}
                                          onClick={handleOpenDeleteModal}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}

                                  <Tooltip title={t("download-document")}>
                                    <IconButton
                                      aria-label="download"
                                      className={classes.margin}
                                      style={{ color: "#4b679d" }}
                                      onClick={handleDownloadDocument}
                                    >
                                      <GetAppIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t("download-report")}>
                                    <IconButton
                                      aria-label="download"
                                      className={classes.margin}
                                      style={{ color: "#3F91F9" }}
                                      onClick={handleDownloadReport}
                                    >
                                      <AssignmentIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t("open-qr-code")}>
                                    <IconButton
                                      aria-label="open-qrcode"
                                      className={classes.margin}
                                      style={{ color: "#4B947A" }}
                                      onClick={handleOpenQrModal}
                                    >
                                      <i className="fas fa-qrcode"></i>
                                    </IconButton>
                                  </Tooltip>

                                  <div style={{ float: "right" }}>
                                    <Button
                                      color="primary"
                                      className={classes.marginRight}
                                      onClick={handleOpenSignModal}
                                    >
                                      <EditIcon className={classes.icons} />{" "}
                                      {t("sign")}
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} lg={7}>
                            <Card>
                              <CardHeader
                                color="lightBlue"
                                icon
                                style={{ display: "inline-flex" }}
                              >
                                <CardIcon color="lightBlue">
                                  <LockOpenIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  {t("access-rights")}
                                </h4>
                                {!isShared && (
                                  <div style={{ marginLeft: "auto" }}>
                                    <Button
                                      style={{ marginTop: "28px" }}
                                      round
                                      color="primary"
                                      onClick={hadleOpenAddModal}
                                    >
                                      {t("add")}
                                    </Button>
                                  </div>
                                )}
                              </CardHeader>
                              <CardBody>
                                <GridContainer>
                                  <>
                                    {documentDetails.accessRights &&
                                    documentDetails.accessRights.length > 0 ? (
                                      <AccessRightsTable
                                        accessRights={
                                          documentDetails.accessRights
                                        }
                                        handleDeleteAccess={handleDeleteAccess}
                                        isShared={isShared}
                                      />
                                    ) : (
                                      <GridItem xs={12}>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            marginTop: "2rem",
                                            marginBottom: "2rem",
                                          }}
                                        >
                                          {t("no-receivers-yet")}
                                        </div>
                                      </GridItem>
                                    )}
                                  </>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} lg={6}>
                            <Card>
                              <CardHeader color="lightBlue" icon>
                                <CardIcon color="lightBlue">
                                  <StorageIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  {t("additional-info")}
                                </h4>
                              </CardHeader>
                              <CardBody>
                                {!isShared ? (
                                  <AddMetadataInputs
                                    currentState={state}
                                    handleSetState={handleSetState}
                                    handleAddData={handleAddData}
                                  />
                                ) : !isMetadataEmpty ? (
                                  <>
                                    {Object.entries(state).map(
                                      ([key, value], i) => {
                                        if (value.value.length > 0) {
                                          return (
                                            <>
                                              <h4
                                                className={
                                                  classes.cardIconTitle
                                                }
                                                style={{ marginTop: "10px" }}
                                              >
                                                {value.value}
                                              </h4>
                                              <h6
                                                className={classes.cardCategory}
                                              >
                                                {key}
                                              </h6>
                                            </>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "2rem",
                                      marginBottom: "2rem",
                                    }}
                                  >
                                    {t("no-additional-info")}
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} lg={6}>
                            <Card>
                              <CardHeader color="lightBlue" icon>
                                <CardIcon color="lightBlue">
                                  <LinkIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  {t("blockchain-info")}
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "10px" }}
                                >
                                  {documentDetails.transactionId}
                                </h4>
                                <h6 className={classes.cardCategory}>TXID</h6>

                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "20px" }}
                                >
                                  {documentDetails.hash}
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {t("hash")}
                                </h6>

                                <h4
                                  className={classes.cardIconTitle}
                                  style={{ marginTop: "20px" }}
                                >
                                  {documentDetails.transactionStatus}
                                </h4>
                                <h6 className={classes.cardCategory}>
                                  {t("status")}
                                </h6>
                                {/* <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    TXID
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentDetails.transactionId}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Hash
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentDetails.hash}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justify="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Status
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentDetails.transactionStatus}
                                  </GridItem>
                                </GridContainer> */}

                                <hr className={classes.footerLine} />
                                <div style={{ display: "inline" }}>
                                  <Tooltip title={t("view-on-chain")}>
                                    <IconButton
                                      aria-label="view-on-chain"
                                      className={classes.margin}
                                      style={{ color: "#3F91F9" }}
                                      onClick={handleOpenBCModal}
                                    >
                                      <LinkIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <div style={{ float: "right" }}>
                                    <Button
                                      color="primary"
                                      className={classes.marginRight}
                                      onClick={handleOpenVerifyHashModal}
                                    >
                                      <CheckIcon className={classes.icons} />{" "}
                                      {t("hash-information")}
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t("signatures"),
                      tabIcon: Gavel,
                      tabContent: (
                        <GridContainer>
                          <GridItem xs={12}>
                            <Card>
                              <CardHeader color="lightBlue" icon>
                                <CardIcon color="lightBlue">
                                  <DescriptionIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  {t("signatures")}
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <SignaturesTable
                                  data={documentDetails.signatures}
                                  currentHash={documentDetails.hash}
                                  handleVerifyHash={handleOpenSignatureModal}
                                  handleDeleteSignature={
                                    handleOpenDeleteSignatureModal
                                  }
                                />
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    showPdfPreview ? previewFile : {},
                    showPdfPreview ? previewReport : {},
                  ]}
                  action={handleLoadPreviewFile}
                  disablePreview={!showPdfPreview}
                />
              </GridItem>
            </GridContainer>

            <ModalComponent
              open={openModal}
              handleClose={handleCloseOrModal}
              dialogTitle={t("document-qr-code")}
              maxWidth="xs"
              dialogContent={
                <div style={{ textAlign: "center" }}>
                  <img height="200px" src={documentQrCode} alt="QR-code" />
                </div>
              }
            />

            <ModalComponent
              open={openDeleteModal}
              handleClose={handleCloseDeleteModal}
              dialogTitle={t("delete-document")}
              maxWidth="xs"
              dialogContent={<div>{t("confirm-delete-document")}</div>}
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseDeleteModal} color="rose">
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteFile}
                  >
                    {t("yes")}
                  </Button>
                </React.Fragment>
              }
            />

            <SnackBarComponent
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              variant={variant}
              message={snackbarMessage}
            />

            <ModalComponent
              open={openBCModal}
              handleClose={handleCloseBCModal}
              dialogTitle={t("view-on-chain")}
              maxWidth="xs"
              dialogContent={
                <div>
                  {documentDetails.transactionStatus === "PREPARED" ? (
                    <p>{t("transaction-is-presigned-but-not-on-blockchain")}</p>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <QRCode
                        size={200}
                        value={`https://whatsonchain.com/tx/${documentDetails.transactionId}`}
                      />
                      <div style={{ textAlign: "center", marginTop: "2rem" }}>
                        <a
                          href={`https://whatsonchain.com/tx/${documentDetails.transactionId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("link")}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              }
            />

            <ModalComponent
              open={openVerifyHashModal}
              handleClose={handleCloseVerifyHashModal}
              dialogTitle={t("document-hash-signature-verification")}
              maxWidth="md"
              dialogContent={
                <>
                  {verificationData.length > 0 && (
                    <HashTimeline steps={verificationData} />
                  )}
                </>
              }
            />

            <ModalComponent
              open={openSignModal}
              handleClose={handleCloseSignModal}
              dialogTitle={t("sign-document")}
              maxWidth="xs"
              dialogContent={<div>{t("confirm-sign-document")}</div>}
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseSignModal} color="rose">
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignDocument}
                  >
                    {t("sign")}
                  </Button>
                </React.Fragment>
              }
            />

            <ModalComponent
              open={openVerifySignatureModal}
              handleClose={handleCloseVerifySignatureModal}
              dialogTitle={t("document-signature-hash-information")}
              maxWidth="md"
              dialogContent={
                <>
                  {signatureVerificationData.length > 0 && (
                    <HashTimeline
                      steps={signatureVerificationData}
                      inputData={inputData}
                    />
                  )}
                </>
              }
            />

            <ModalComponent
              open={openDeleteSignatureModal}
              handleClose={handleCloseDeleteSignatureModal}
              dialogTitle={t("delete-signature")}
              maxWidth="xs"
              dialogContent={<div>{t("confirm-delete-signature")}</div>}
              dialogActions={
                <React.Fragment>
                  <Button
                    simple
                    onClick={handleCloseDeleteSignatureModal}
                    color="rose"
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteSignature}
                  >
                    {t("yes")}
                  </Button>
                </React.Fragment>
              }
            />

            <ModalComponent
              open={openAddModal}
              handleClose={handleCloseAddModal}
              dialogTitle={t("access-control")}
              maxWidth="xs"
              dialogContent={
                <>
                  {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    onChange={(event, value) => handleAddEmailAddresses(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={t("email-address-es")}
                        placeholder={t("add-one-or-more-email-addresses")}
                      />
                    )}
                  />
                </>
              }
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseAddModal} color="rose">
                    {t("cancel")}
                  </Button>
                  {loading ? (
                    <Button round color="primary" disabled={loading}>
                      <span className={classes.icons}>
                        <ClipLoader
                          size={18}
                          color={"#fff"}
                          loading={loading}
                        />
                      </span>
                      {t("adding")}
                    </Button>
                  ) : (
                    <Button
                      round
                      color="primary"
                      onClick={handleMulitpleGiveAccess}
                      disabled={emails.length === 0}
                    >
                      {t("add")}
                    </Button>
                  )}
                </React.Fragment>
              }
            />
          </>
        )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  {!userProfile.requestDocumentsValidation ? (
                    <SupervisedUserCircleIcon className={classes.iconPrimary} />
                  ) : (
                    <HourglassEmptyIcon className={classes.iconPrimary} />
                  )}
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {!userProfile.requestDocumentsValidation
                    ? t("in-order-to-proceed")
                    : t("waiting-for-document-validation")}
                </h5>
                {!userProfile.requestDocumentsValidation ? (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail && t("validate-email")}
                    {!userProfile.validatedEmail &&
                      (!userProfile.validatedDocuments ? " and " : ".")}
                    {!userProfile.validatedDocuments &&
                      t("request-a-document-validation")}
                  </p>
                ) : (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail &&
                      t("please-validate-your-email")}
                  </p>
                )}
                <Link to="/admin/user-page" style={{ color: "#D7A31A" }}>
                  {t("go-to-user-profile")}
                </Link>
              </CardBody>
            </Card>
          </>
        )}
    </>
  );
};

export default DocumentDetailsPage;
