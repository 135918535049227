import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LockIcon from "@material-ui/icons/Lock";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
//import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ApiService from "api/ApiService.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ModalComponent from "components/ModalComponent.js";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SnackBarComponent from "components/SnackBarComponent.js";
import defaultImage from "assets/img/default-avatar.png";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
// import defaultSignature from "assets/img/signature.png";
// import UserDocumentUpload from "components/CustomUpload/UserDocumentUpload.js";
import DocumentUpload from "components/CustomUpload/DocumentUpload.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import ProfileCard from "components/ProfileCard/ProfileCard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#D7A31A",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#D7A31A",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#D7A31A",
        },
      },
    },
  },
});
export default function UserProfile(props) {
  const { t } = useTranslation();
  const [userProfileData, setUserProfileData] = React.useState({});
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
  const [signature, setSignature] = React.useState(undefined);
  const [uploadSignatureLoading, setUploadSignatureLoading] = React.useState(
    false
  );
  const [companyDocuments, setCompanyDocuments] = React.useState(undefined);
  const [
    uploadCompanyDocumentsLoading,
    setUploadCompanyDocumentsLoading,
  ] = React.useState(false);
  const [userIdDocument, setUserIdDocument] = React.useState(undefined);
  const [userIdDocumentLoading, setUserIdDocumentLoading] = React.useState(
    false
  );

  const [toggle2FA, setToggle2FA] = React.useState(false);

  const [token2FA, setToken2FA] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [qrCodeSecret2FA, setQrCodeSecret2FA] = React.useState(null);
  const [disableModal, setDisableModal] = React.useState(false);

  const [message, setMessage] = React.useState("");
  const [variant, setVariant] = React.useState("success");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const [userName, setUserName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");

  //const [query, setQuery] = React.useState({});
  const [validateEmailMessage, setValidateEmailMessage] = React.useState("");
  const [
    validateDocumentsMessage,
    setValidateDocumentsMessage,
  ] = React.useState("");
  const [requestedValidation, setRequestedValidation] = React.useState("");

  React.useEffect(() => {
    getUserAvatar();
    getUserSignature();
    getCompanyDocuments();
    getUserIdDocument();
  }, []);

  const getUserAvatar = async () => {
    try {
      const avatarRes = await ApiService.readUserDocument({
        documentCriteria: ApiService.documentCriteria.documentType,
        documentFilter: ApiService.documentTypes.avatar,
      });
      setImagePreviewUrl(`data:image/png;base64, ${avatarRes.contents}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserSignature = async () => {
    try {
      const signatureRes = await ApiService.readUserDocument({
        documentCriteria: ApiService.documentCriteria.documentType,
        documentFilter: ApiService.documentTypes.signature,
      });
      setSignature(signatureRes);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyDocuments = async () => {
    try {
      const companyDocumentsRes = await ApiService.readUserDocument({
        documentCriteria: ApiService.documentCriteria.documentType,
        documentFilter: ApiService.documentTypes.companyDocuments,
      });
      setCompanyDocuments(companyDocumentsRes);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserIdDocument = async () => {
    try {
      const userIdDocumentRes = await ApiService.readUserDocument({
        documentCriteria: ApiService.documentCriteria.documentType,
        documentFilter: ApiService.documentTypes.userId,
      });
      setUserIdDocument(userIdDocumentRes);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseOpenDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteProfile = async () => {
    try {
      await ApiService.deleteUser({ userId: userProfileData._id });
      //todo
      setMessage(t("profile-deletion-process-started"));
      setVariant("info");
      setOpenSnackbar(true);
    } catch (e) {
      console.error(e);
      setMessage(t("error-deleting-profile"));
      setVariant("danger");
      setOpenSnackbar(true);
    }
    setOpenDeleteModal(false);
    window.location.href = "/auth/login-page";
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChangeToken = (event) => {
    setToken2FA(event.target.value);
  };

  const handleCloseEnableModal = () => {
    setOpenModal(false);
    setToggle2FA(false);
  };

  const handle2FAAction = async (toggleActive) => {
    if (userProfileData.enabled2FA) {
      if (toggleActive) {
        setToggle2FA(true);
        setQrCodeSecret2FA(null);
        setOpenModal(false);
        setToken2FA("");
      } else {
        setDisableModal(true);
      }
    } else {
      if (toggleActive) {
        try {
          const { qrCode } = await ApiService.generate2FASecret({
            userId: userProfileData._id,
          });
          setQrCodeSecret2FA(qrCode);
          setToggle2FA(true);
          setOpenModal(true);
        } catch (e) {
          console.error(e);
          setOpenSnackbar(true);
          setVariant("warning");
          setMessage(e.message);
        }
      } else {
        setQrCodeSecret2FA(null);
        setOpenModal(false);
        setToggle2FA(false);
      }
      setToken2FA("");
    }
  };

  const handleEnable2FASubmit = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.enable2FA({
        token2FA,
      });
      //await handleRefresh();
      setMessage(responseData.message);
    } catch (e) {
      setToken2FA("");
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setMessage(e.message);
    }
    setOpenModal(false);

    try {
      const userProfileData = await ApiService.readUser();
      setUserProfileData(userProfileData);
      setToggle2FA(userProfileData.enabled2FA);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const handleCloseDisableModal = () => {
    setDisableModal(false);
  };

  const handleDisable2FA = async () => {
    try {
      await ApiService.disable2FA({
        userId: userProfileData._id,
      });
      //showSuccessMessage(responseData.message);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setMessage(e.message);
    }
    setToggle2FA(false);
    setQrCodeSecret2FA(null);
    setDisableModal(false);
    setToken2FA("");

    try {
      const userProfileData = await ApiService.readUser();
      setUserProfileData(userProfileData);
      setToggle2FA(userProfileData.enabled2FA);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const handleLogoutAll = async () => {
    try {
      await ApiService.logoutUserAllSessions(
        { userId: userProfileData._id },
        props.history
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateProfile = async () => {
    const requestData = {
      userId: userProfileData._id,
    };
    if (userName.length > 0) {
      requestData.name = userName;
    }
    if (companyName.length > 0) {
      requestData.companyName = companyName;
    }
    if (jobTitle.length > 0) {
      requestData.companyPosition = jobTitle;
    }

    try {
      await ApiService.updateUser(requestData);
      setOpenSnackbar(true);
      setVariant("success");
      setMessage(t("successfully-updated-profile"));
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("error");
      setMessage(e.message);
    }
  };

  const handleRequestValidation = async () => {
    const requestData = {
      userId: userProfileData._id,
      requestDocumentsValidation: true,
    };
    try {
      await ApiService.requestUserDocumentsValidation(requestData);
      setRequestedValidation(t("requested-document-validation"));
      setMessage(t("sucessfully-requested-document-validation"));
      setVariant("success");
      setOpenSnackbar(true);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("error");
      setMessage(e.message);
    }
  };

  const handleSendValidationEmail = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.sendUserValidateEmailLink({
        userId: userProfileData._id,
      });
      setOpenSnackbar(true);
      setVariant("success");
      setMessage(responseData.message);
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("error");
      setMessage(e.message);
    }
  };

  // const handleCloseEmailMessage = () => {
  //   setValidateEmailMessage("");
  // };
  //   const handleCloseDocumentsMessage = () => {
  //     setValidateDocumentsMessage("");
  //   };
  //   const handleCloseRequestedValidation = () => {
  //     setRequestedValidation("");
  //   };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        //const returnData = await ApiService.loginRequired(signal, false);
        const userProfileData = await ApiService.readUser(signal);
        if (!userProfileData.validatedEmail) {
          setValidateEmailMessage(
            t("check-your-email-and-locate-the-confirmation-email")
          );
        }
        if (
          !userProfileData.validatedDocuments &&
          !userProfileData.requestDocumentsValidation
        ) {
          setValidateDocumentsMessage(
            t(
              "upload-your-used-id-and-company-documents-and-request-a-document-validation"
            )
          );
        }
        if (userProfileData.requestDocumentsValidation) {
          setRequestedValidation(t("requested-document-validation"));
        }
        setUserProfileData(userProfileData);
        setToggle2FA(userProfileData.enabled2FA);
        setUserName(userProfileData.name);
        if (userProfileData.organizations.length > 0) {
          setCompanyName(userProfileData.organizations[0].name);
          if (userProfileData.organizations[0].positions.length > 0) {
            setJobTitle(userProfileData.organizations[0].positions[0].name);
          }
        }

        // let queryValue;
        // if (returnData.superuser && userProfileData._id) {
        //   const userProfile = await ApiService.readUser(
        //     {
        //       userId: userProfileData._id,
        //     },
        //     signal
        //   );
        //   queryValue = {
        //     superuser: returnData.superuser,
        //     ownAccount: returnData._id === userProfile._id,
        //     userId: userProfile._id,
        //   };
        // } else {
        //   queryValue = {
        //     superuser: returnData.superuser,
        //     ownAccount: true,
        //     userId: returnData._id,
        //   };
        // }
        // setQuery(queryValue);
      } catch (e) {
        console.error(e);
      }
    };

    isMounted && apiOperations();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [t]);

  const handleDownloadDocument = async (file) => {
    try {
      const responseData = await ApiService.downloadUserDocument({
        userId: userProfileData._id,
        documentCriteria: ApiService.documentCriteria.documentId,
        documentFilter: file?.document?._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = file?.document?.fileName;
      a.click();
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const handleUploadSignature = async (file, type) => {
    try {
      setUploadSignatureLoading(true);

      const { message } = await ApiService.upsertUserImage({
        userId: userProfileData._id,
        documentType: type,
        encryptDocument: "true",
        resizeImage: true,
        imageWidth: 300,
        imageHeight: 200,
        file,
      });

      getUserSignature();

      setMessage(message);
      setVariant("success");
      setOpenSnackbar(true);
    } catch (error) {
      setMessage(error.message);
      setVariant("error");
      setOpenSnackbar(true);
    } finally {
      setUploadSignatureLoading(false);
    }
  };

  const handleUploadCompanyDocuments = async (file, type) => {
    try {
      setUploadCompanyDocumentsLoading(true);

      const { message } = await ApiService.upsertUserFile({
        userId: userProfileData._id,
        documentType: type,
        encryptDocument: "true",
        file,
      });

      getCompanyDocuments();

      setMessage(message);
      setVariant("success");
      setOpenSnackbar(true);
    } catch (error) {
      setMessage(error.message);
      setVariant("error");
      setOpenSnackbar(true);
    } finally {
      setUploadCompanyDocumentsLoading(false);
    }
  };

  const handleUploadUserId = async (file, type) => {
    try {
      setUserIdDocumentLoading(true);

      const { message } = await ApiService.upsertUserFile({
        userId: userProfileData._id,
        documentType: type,
        encryptDocument: "true",
        file,
      });

      getUserIdDocument();

      setMessage(message);
      setVariant("success");
      setOpenSnackbar(true);
    } catch (error) {
      setMessage(error.message);
      setVariant("error");
      setOpenSnackbar(true);
    } finally {
      setUserIdDocumentLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={0}>
        {requestedValidation.length > 0 && (
          <Grid item xs={12}>
            <SnackbarContent message={requestedValidation} color="info" />
          </Grid>
        )}
        {validateEmailMessage.length > 0 && (
          <Grid item xs={12}>
            <SnackbarContent
              message={validateEmailMessage}
              button={t("resend-validation-link")}
              color="warning"
              click={handleSendValidationEmail}
            />
          </Grid>
        )}
        {validateDocumentsMessage.length > 0 && (
          <Grid item xs={12}>
            <SnackbarContent
              message={validateDocumentsMessage}
              color="warning"
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  avatar={
                    <CardIcon color="primary">
                      <PermIdentity />
                    </CardIcon>
                  }
                  title={
                    <h4 className={classes.cardIconTitle}>
                      {t("edit-profile")}
                    </h4>
                  }
                />
                <CardBody>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ProfileCard
                        userId={userProfileData._id}
                        documentType={"AVATAR"}
                        image={imagePreviewUrl}
                        name={userProfileData.name}
                        email={userProfileData.email}
                        organizations={userProfileData.organizations}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <CustomInput
                        labelText={t("name")}
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setUserName(e.target.value);
                          },
                          value: userName,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        labelText={t("company-name")}
                        id="company-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setCompanyName(e.target.value);
                          },
                          value: companyName,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        labelText={t("job-title")}
                        id="job-title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            setJobTitle(e.target.value);
                          },
                          value: jobTitle,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    color="primary"
                    className={classes.updateProfileButton}
                    onClick={handleUpdateProfile}
                  >
                    {t("update-profile")}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader
                  avatar={
                    <CardIcon color="primary">
                      <InsertDriveFileIcon />
                    </CardIcon>
                  }
                  title={
                    <h4 className={classes.cardIconTitle}>
                      {t("documents-title")}
                    </h4>
                  }
                />
                <CardBody>
                  <Grid container>
                    <>
                      <Grid item xs={12}>
                        <DocumentUpload
                          title={t("signature")}
                          updatedAt={signature?.document?.updatedAt}
                          image={
                            signature
                              ? `data:image/png;base64, ${signature?.contents}`
                              : null
                          }
                          type={signature?.document?.dataType}
                          onUpload={(file) =>
                            handleUploadSignature(
                              file,
                              ApiService.documentTypes.signature
                            )
                          }
                          onDownload={() => handleDownloadDocument(signature)}
                          loading={uploadSignatureLoading}
                          editable={true}
                        />
                        <DocumentUpload
                          title={t("company-documents")}
                          updatedAt={companyDocuments?.document?.updatedAt}
                          image={
                            companyDocuments
                              ? `data:image/png;base64, ${companyDocuments?.contents}`
                              : null
                          }
                          fileName={companyDocuments?.document?.fileName}
                          type={companyDocuments?.document?.dataType}
                          onUpload={(file) =>
                            handleUploadCompanyDocuments(
                              file,
                              ApiService.documentTypes.companyDocuments
                            )
                          }
                          onDownload={() =>
                            handleDownloadDocument(companyDocuments)
                          }
                          loading={uploadCompanyDocumentsLoading}
                          editable={!userProfileData.validatedDocuments}
                        />
                        <DocumentUpload
                          title={t("user-id")}
                          updatedAt={userIdDocument?.document?.updatedAt}
                          image={
                            userIdDocument
                              ? `data:image/png;base64, ${userIdDocument?.contents}`
                              : null
                          }
                          fileName={userIdDocument?.document?.fileName}
                          type={userIdDocument?.document?.dataType}
                          onUpload={(file) =>
                            handleUploadUserId(
                              file,
                              ApiService.documentTypes.userId
                            )
                          }
                          onDownload={() =>
                            handleDownloadDocument(userIdDocument)
                          }
                          loading={userIdDocumentLoading}
                          editable={!userProfileData.validatedDocuments}
                        />
                      </Grid>
                    </>
                  </Grid>
                  <Button
                    disabled={userProfileData.validatedDocuments}
                    color={
                      userProfileData.validatedDocuments ? "success" : "primary"
                    }
                    className={classes.updateProfileButton}
                    onClick={handleRequestValidation}
                    startIcon={
                      userProfileData.validatedDocuments && <CheckIcon />
                    }
                  >
                    {userProfileData.validatedDocuments
                      ? t("validated")
                      : t("request-a-document-validation")}
                  </Button>
                  <Clearfix />
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  avatar={
                    <CardIcon color="primary">
                      <LockIcon />
                    </CardIcon>
                  }
                  title={
                    <h4 className={classes.cardIconTitle}>{t("security")}</h4>
                  }
                />
                <CardBody>
                  <GridContainer alignItems="baseline">
                    <GridItem xs={8}>
                      <p className={classes.description}>
                        {t("two-factor-authentication")}
                      </p>
                    </GridItem>
                    <GridItem xs={4} style={{ textAlign: "right" }}>
                      <ThemeProvider theme={theme}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={toggle2FA}
                              onChange={(event) => {
                                handle2FAAction(event.target.checked);
                              }}
                              value="toggle2FA"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                        />
                      </ThemeProvider>
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="baseline">
                    <GridItem xs={8}>
                      <p className={classes.description}>
                        {t("logout")} {userProfileData.activeTokens}{" "}
                        {t("sessions")}
                      </p>
                    </GridItem>
                    <GridItem xs={4} style={{ textAlign: "right" }}>
                      <Button simple color="warning" onClick={handleLogoutAll}>
                        {t("logout")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="baseline">
                    <GridItem xs={8}>
                      <p className={classes.description}>
                        {t("delete-profile")}
                      </p>
                    </GridItem>
                    <GridItem xs={4} style={{ textAlign: "right" }}>
                      <Button
                        simple
                        color="danger"
                        variant="outlined"
                        onClick={handleOpenDeleteModal}
                      >
                        {t("delete")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader
                  avatar={
                    <CardIcon color="primary">
                      <CreditCardIcon />
                    </CardIcon>
                  }
                  title={
                    <h4 className={classes.cardIconTitle}>{t("credit")}</h4>
                  }
                />
                <CardBody>
                  <GridContainer alignItems="baseline">
                    <GridItem xs={8}>
                      <p className={classes.description}>{t("credit")}</p>
                    </GridItem>
                    <GridItem xs={4} style={{ textAlign: "right" }}>
                      {userProfileData.credits}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ModalComponent
        open={openModal}
        handleClose={handleCloseEnableModal}
        dialogTitle={t("enable-2fa")}
        dialogContent={
          <>
            <GridContainer container style={{ textAlign: "center" }}>
              <GridItem item xs={12}>
                <Typography variant="caption">{t("scan-qr-code")}</Typography>
              </GridItem>
              <GridItem item xs={12}>
                <img src={qrCodeSecret2FA} alt={t("2fa-qr-code-secret")} />
              </GridItem>
              <GridItem item xs={12}>
                <TextField
                  id="outlined-basic"
                  label={t("2fa-confirmation-token")}
                  variant="outlined"
                  value={token2FA}
                  onChange={handleChangeToken}
                />
              </GridItem>
            </GridContainer>
          </>
        }
        maxWidth="sm"
        dialogActions={
          <React.Fragment>
            <Button
              simple
              onClick={handleCloseEnableModal}
              style={{ color: "#A5ABBD" }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleEnable2FASubmit}
              variant="contained"
              color="primary"
              disabled={token2FA.length === 0}
            >
              {t("enable")}
            </Button>
          </React.Fragment>
        }
      />

      <ModalComponent
        open={disableModal}
        handleClose={handleCloseDisableModal}
        dialogTitle={t("disable-2fa")}
        maxWidth="xs"
        dialogContent={
          <div>{t("confirm-disable-two-factor-authentication")}</div>
        }
        dialogActions={
          <React.Fragment>
            <Button
              simple
              onClick={handleCloseDisableModal}
              style={{ color: "#A5ABBD" }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleDisable2FA}
              variant="contained"
              color="primary"
            >
              {t("yes")}
            </Button>
          </React.Fragment>
        }
      />

      <ModalComponent
        open={openDeleteModal}
        handleClose={handleCloseOpenDeleteModal}
        dialogTitle={t("delete-profile")}
        maxWidth="md"
        dialogContent={<h4>{t("deleting-profile-description")}</h4>}
        dialogActions={
          <React.Fragment>
            <Button
              simple
              onClick={handleCloseOpenDeleteModal}
              style={{ color: "#A5ABBD" }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleDeleteProfile}
              variant="contained"
              color="danger"
            >
              {t("yes")}
            </Button>
          </React.Fragment>
        }
      />

      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={message}
      />
    </div>
  );
}
