import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import ApiService from "api/ApiService.js";
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import ItemExpansionPanelSummary from "components/ItemExpansionPanelSummary";
import ExpansionPanelComponent from "components/ExpansionPanelComponent";
import ItemExpansionPanelDetails from "components/ItemExpansionPanelDetails";
import ModalComponent from "components/ModalComponent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { formatDate } from "api/utils";
import ppStyles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ClipLoader from "react-spinners/ClipLoader";
import LinearProgress from "@material-ui/core/LinearProgress";
import LinkIcon from "@material-ui/icons/Link";
import CheckIcon from "@material-ui/icons/Check";
import QRCode from "react-qr-code";
import HashTimeline from "./HashTimeline";
import EcoIcon from "@material-ui/icons/Eco";
import PeopleIcon from "@material-ui/icons/People";
import GavelIcon from "@material-ui/icons/Gavel";
import Big from "big.js";
import SnackBarComponent from "components/SnackBarComponent.js";

const AssessmentDetailsPage = (props) => {
  const containerId = props.match.params.id;
  const ppClasses = useppStyles();
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [metricLoading, setMetricLoading] = useState(false);
  const [documentContainer, setDocumentContainer] = useState({});
  const [showAddMetric, setShowAddMetric] = useState(false);
  const [metricName, setMetricName] = useState("");
  const [metricUnit, setMetricUnit] = useState("");
  const [selectedEnabled, setSelectedEnabled] = useState("Environmental");
  const [indexCode, setIndexCode] = useState("");
  const [framework, setFramework] = useState("");
  const [errors, setErrors] = useState("");
  const [addMetadataResponse, setAddMetadataResponse] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [auditor, setAuditor] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [role, setRole] = useState("");
  const [containerOwner, setContainerOwner] = useState("");
  const [openSignModal, setOpenSignModal] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const [signAssessment, setSignAssessment] = useState({});
  const [openBCModal, setOpenBCModal] = useState(false);
  const [signatureDetails, setSignatureDetails] = useState({});
  const [verificationData, setVerificationData] = useState([]);
  const [openVerifyHashModal, setOpenVerifyHashModal] = useState(false);
  const [auditorEmail, setAuditorEmail] = useState("");
  const [openInviteAuditorModal, setOpenInviteAuditorModal] = useState(false);
  const [addAccessResponse, setAddAccessResponse] = useState({});
  const [deleteAccessResponse, setDeleteAccessResponse] = useState({});
  const [lookupUsers, setLookupUsers] = useState({});
  const [envHeaders, setEnvHeaders] = useState([]);
  const [socHeaders, setSocHeaders] = useState([]);
  const [govHeaders, setGovHeaders] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [variant, setVariant] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    let isMounted = true;

    const apiOperations = async () => {
      if (refresh) {
        setRefresh(false);
      }
      try {
        const userProfileData = await ApiService.readUser();
        setUserProfile(userProfileData);
        const queryDocumentContainerParams = {
          documentContainerId: containerId,
        };
        const responseData = await ApiService.getDocumentContainer(
          queryDocumentContainerParams
        );
        responseData.message = JSON.parse(responseData.message);

        const headers = responseData.metadata.filter((data) =>
          data.name.startsWith("esgMetric_")
        );
        const metricEntry = responseData.metadata.filter((data) =>
          data.name.startsWith("esgMetricEntry_")
        );
        const parsedMetricEntryMetadata = metricEntry.map((data) => {
          data.metadata = JSON.parse(data.metadata);
          return data;
        });
        const parsedMetricEntry = parsedMetricEntryMetadata.map((data) => {
          data.attachments = data.metadata.attachments
            ? responseData.packages.filter(
                (file) =>
                  file.data_id === JSON.parse(data.metadata.attachments)[0]
              )
            : [];
          return data;
        });

        const parsedHeaders = headers.map((header, index) => {
          header.metadata = JSON.parse(header.metadata);
          header.entry = parsedMetricEntry.filter(
            (metric) => metric.name[15] === header.name[10]
          );

          header.metadata.total = header.entry
            .filter((entry) => entry.flags.length === 2)
            .reduce((a, b) => {
              const value = new Big(b.metadata.value);
              return a.plus(value);
            }, new Big(0))
            .toString();
          return header;
        });

        const enviromentHeaders = parsedHeaders.filter(
          (header) =>
            JSON.parse(header.metadata.category)[0] === "Environmental"
        );
        setEnvHeaders(enviromentHeaders);

        const socialHeaders = parsedHeaders.filter(
          (header) => JSON.parse(header.metadata.category)[0] === "Social"
        );
        setSocHeaders(socialHeaders);

        const governanceHeaders = parsedHeaders.filter(
          (header) => JSON.parse(header.metadata.category)[0] === "Governance"
        );

        setGovHeaders(governanceHeaders);

        //setMetricHeaders(parsedHeaders);
        setDocumentContainer(responseData);

        const auditorList = responseData.accessRights.filter(
          (access) => access.type === "SIGN"
        );
        if (auditorList.length > 0) {
          setAuditor({ email: auditorList[0].email, id: auditorList[0]._id });
        }
        setLoading(false);
        setMetricLoading(false);
        setShowAddMetric(false);
      } catch (e) {
        setLoading(false);
        setMetricLoading(false);
        setShowAddMetric(false);
        console.error(e);
      }
    };

    isMounted && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [
    addMetadataResponse,
    addAccessResponse,
    deleteAccessResponse,
    refresh,
    containerId,
    signAssessment,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isObjectEmpty(lookupUsers)) {
      const getUsers = async () => {
        const responseData = await ApiService.getAllUsers({});
        const users = {};
        for (const entry of responseData) {
          users[entry.address] = entry.user.email;
        }
        setLookupUsers(users);
      };
      getUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [lookupUsers]);

  // check role
  useEffect(() => {
    if (
      !isObjectEmpty(userProfile) &&
      !isObjectEmpty(lookupUsers) &&
      !isObjectEmpty(documentContainer)
    ) {
      const email = userProfile.email;
      const sender = documentContainer.sender; // owner
      const isOwner = lookupUsers[sender] === email;
      const owner = lookupUsers[sender];
      setContainerOwner(owner);
      if (isOwner) {
        setRole("owner");
        return;
      }
      const isAuditor =
        documentContainer.accessRights.filter(
          (access) => access.email === email && access.type === "SIGN"
        ).length > 0;
      if (isAuditor) {
        setRole("auditor");
        return;
      }
      const isConfirmingParty =
        documentContainer.accessRights.filter(
          (access) => access.email === email && access.type === "READ"
        ).length > 0;
      if (isConfirmingParty) {
        setRole("confirming-party");
        return;
      }
    }
  }, [userProfile, lookupUsers, documentContainer]);

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDownloadReport = () => {
    const handlePreviewFile = async (userId, parameters, signal, type) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(
        reportParams,
        signal
      );

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentContainer._id,
        parentType: "CONTAINER",
        frontendUrl: getFrontendUrl(),
        creatorName: "Abendum MVP",
        documentTitle: "Abendum MVP Report",
        reportType: "ESG",
        reportTitle: t("report"),
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(
          reportParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
        return;
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
    };
    handlePrepareFileDownload();
  };

  // modals for signature verification and view on chain
  const handleOpenBCModal = (signature) => {
    if (signature.length > 0) {
      setSignatureDetails(signature[0]);
      setOpenBCModal(true);
    }
  };

  const handleCloseBCModal = () => {
    setSignatureDetails({});
    setOpenBCModal(false);
  };

  const handleOpenVerifyHashModal = async (signature) => {
    if (signature.length > 0) {
      setOpenVerifyHashModal(true);
      try {
        const response = await ApiService.verifySignature({
          signatureId: signature[0]._id,
          parentId: signature[0].parentId,
          parentType: "CONTAINER",
        });
        setVerificationData(response.verificationSteps);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseVerifyHashModal = () => {
    setOpenVerifyHashModal(false);
  };

  const refreshContainer = () => {
    setRefresh(true);
  };

  const handleChangeEnabled = (event) => {
    setSelectedEnabled(event.target.value);
  };

  // show-hide add new metric component
  const handleShowAddMetric = () => {
    setShowAddMetric(true);
  };

  const handleHideShowMetric = () => {
    setShowAddMetric(false);
  };

  // save metric - add metadata
  const handleSaveMetric = async () => {
    const validName = metricName.length > 0;
    const validUnit = metricUnit.length > 0;

    const checksPassed = validName && validUnit;
    if (!checksPassed) {
      if (!validName) {
        setErrors(t("invalid-name"));
        return;
      }
      if (!validUnit) {
        setErrors(t("invalid-unit"));
        return;
      }
    }

    const index = documentContainer.metadata.length;

    const params = {
      parentId: containerId,
      parentType: "CONTAINER",
      name: `esgMetric_${index}`,
      type: "application/json",
      metadata: {
        index,
        type: "metricHeader",
        name: metricName,
        unit: metricUnit,
        category: JSON.stringify([selectedEnabled]),
      },
      flags: [
        { name: "esg", value: true },
        { name: "esgMetric", value: true },
      ],
    };

    if (framework.length > 0) {
      params.metadata.framework = framework;
    }
    if (indexCode.length > 0) {
      params.metadata.indexCode = indexCode;
    }

    try {
      setMetricLoading(true);
      const responseData = await ApiService.addMetadata(params);
      setAddMetadataResponse(responseData);
    } catch (e) {
      console.error(e);
      setMetricLoading(false);
    }
  };

  const handleUpdateView = (documentContainer) => {
    documentContainer.message = JSON.parse(documentContainer.message);
    const headers = documentContainer.metadata.filter((data) =>
      data.name.startsWith("esgMetric_")
    );
    const metricEntry = documentContainer.metadata.filter((data) =>
      data.name.startsWith("esgMetricEntry_")
    );
    const parsedMetricEntryMetadata = metricEntry.map((data) => {
      data.metadata = JSON.parse(data.metadata);
      return data;
    });

    const parsedMetricEntry = parsedMetricEntryMetadata.map((data) => {
      data.attachments = data.metadata.attachments
        ? documentContainer.packages.filter(
            (file) => file.data_id === JSON.parse(data.metadata.attachments)[0]
          )
        : [];
      return data;
    });

    const parsedHeaders = headers.map((header, index) => {
      header.metadata = JSON.parse(header.metadata);
      header.entry = parsedMetricEntry.filter(
        (metric) => metric.name[15] === header.name[10]
      );
      header.metadata.total = header.entry
        .filter((entry) => entry.flags.length === 2)
        .reduce((a, b) => {
          const value = new Big(b.metadata.value);
          return value.plus(a);
        }, new Big(0))
        .toNumber();
      return header;
    });

    const enviromentHeaders = parsedHeaders.filter(
      (header) => JSON.parse(header.metadata.category)[0] === "Environmental"
    );
    setEnvHeaders(enviromentHeaders);

    const socialHeaders = parsedHeaders.filter(
      (header) => JSON.parse(header.metadata.category)[0] === "Social"
    );
    setSocHeaders(socialHeaders);

    const governanceHeaders = parsedHeaders.filter(
      (header) => JSON.parse(header.metadata.category)[0] === "Governance"
    );

    setGovHeaders(governanceHeaders);

    //setMetricHeaders(parsedHeaders);
    setDocumentContainer(documentContainer);
  };

  const handleOpenInviteAuditorModal = () => {
    setOpenInviteAuditorModal(true);
  };

  const handleCloseModal = () => {
    setOpenInviteAuditorModal(false);
  };

  const handleOpenSignModal = () => {
    setOpenSignModal(true);
  };
  const handleCloseSignModal = () => {
    setOpenSignModal(false);
  };

  const handleSignAssessment = async () => {
    setSignLoading(true);
    // sign entry
    const signParams = {
      parentId: documentContainer._id,
      parentType: "CONTAINER",
      access: "PRIVATE",
    };
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(signParams, signal);
    } catch (e) {
      console.error(e.message);
      setErrors(e.message);
    } finally {
      setSignLoading(false);
      setOpenSignModal(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          setErrors(t("error-downloading-package"));
          setSignLoading(false);
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setSignAssessment(responseData);
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        setErrors(t("operation-aborted"));
        setSignLoading(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setErrors(e.message);
      setSignLoading(false);
    } finally {
      // update view
      setSignLoading(false);
      setOpenSignModal(false);
    }
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      redirectPath: `/admin/metrics/assessment/${documentContainer._id}`,
    });
    return url.toString();
  };

  const handleSendInvite = async () => {
    if (auditorEmail.length > 0) {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      let returnData;
      try {
        returnData = await ApiService.addAccessRights(
          {
            parentId: documentContainer._id,
            parentType: "CONTAINER",
            type: "SIGN",
            email: auditorEmail,
            endOfLife: "-1",
          },
          signal
        );

        setAddAccessResponse(returnData);
      } catch (e) {
        console.error(e);
        return;
      }

      const params = {
        parentId: documentContainer._id,
        parentType: "CONTAINER",
        accessRightsId: returnData.accessRights.filter(
          (user) => user.email === auditorEmail
        )[0]._id,
        frontendUrl: getFrontendUrl(),
      };

      try {
        await ApiService.inviteAccessRights(params, signal);
        setOpenInviteAuditorModal(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDeleteAuditorAccess = async (e) => {
    // Deletion parameters
    const params = {
      parentId: containerId,
      parentType: "CONTAINER",
      accessRightsId: auditor.id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      const response = await ApiService.deleteAccessRights(params, signal);
      setDeleteAccessResponse(response);
      setAuditor({});
    } catch (e) {}
  };

  const headerText = {
    metadata: {
      name: t("metric-name"),
      total: t("total"),
      unit: t("unit"),
      framework: t("framework"),
      indexCode: t("index"),
      confirmations: t("confirmations"),
      status: t("status"),
    },
  };

  const addMetric = (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <AddIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{t("new-metric")}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={`${t("name")} (${t("eg-water-consumption")}))`}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: metricName || "",
                  onChange: (e) => {
                    setMetricName(e.target.value);
                  },
                  //laceholder: `${t("name")} (${t("eg-water-consumption")})`,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={`${t("unit")} (${t("eg-unit")})`}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: metricUnit || "",
                  onChange: (e) => {
                    setMetricUnit(e.target.value);
                  },
                  //placeholder: `${t("unit")} (${t("eg-unit")})`,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={2}>
              <FormLabel
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                {t("category")}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === t("environmental")}
                      onChange={handleChangeEnabled}
                      value={t("environmental")}
                      name={t("radio-button-enabled")}
                      aria-label={t("environmental")}
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={t("environmental")}
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === t("social")}
                      onChange={handleChangeEnabled}
                      value={t("social")}
                      name={t("radio-button-enabled")}
                      aria-label={t("social")}
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={t("social")}
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === t("governance")}
                      onChange={handleChangeEnabled}
                      value={t("governance")}
                      name={t("radio-button-enabled")}
                      aria-label={t("governance")}
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={t("governance")}
                />
              </div>
            </GridItem>

            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={`${t("framework")} (${t("eg-framework")})`}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: framework || "",
                  onChange: (e) => {
                    setFramework(e.target.value);
                  },
                  //placeholder: `${t("framework")} (${t("eg-framework")})`,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={`${t("index-code-optional")} (${t("eg-code")})`}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: indexCode || "",
                  onChange: (e) => {
                    setIndexCode(e.target.value);
                  },
                  //placeholder: `${t("index-code-optional")} (${t("eg-code")})`,
                }}
              />
            </GridItem>
          </GridContainer>
          {errors.length > 0 && <h6 style={{ color: "red" }}>{errors}</h6>}

          {metricLoading && (
            <div className={classes.root}>
              <LinearProgress
                style={{ marginTop: "10px", marginBottom: "15px" }}
              />
            </div>
          )}
          <div style={{ display: "inline" }}>
            <div style={{ float: "right" }}>
              <Button
                disabled={metricLoading}
                color="primary"
                onClick={handleSaveMetric}
              >
                {t("save")}
              </Button>
            </div>
            <div style={{ float: "right" }}>
              <Button
                disabled={metricLoading}
                color="rose"
                simple
                onClick={handleHideShowMetric}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GridContainer alignItems="flex-start">
            <GridItem
              xs={11}
              sm={4}
              md={6}
              style={{ display: "grid", marginBottom: "40px" }}
            >
              <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                <h6 className={classes.cardCategory} style={{ width: "50px" }}>
                  TITLE
                </h6>
                <h4 style={{ marginLeft: "20px" }}>
                  {documentContainer.title}
                </h4>
              </div>

              <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                <h6 className={classes.cardCategory} style={{ width: "50px" }}>
                  STATUS
                </h6>
                <h4 style={{ marginLeft: "20px" }}>
                  {!isObjectEmpty(documentContainer) &&
                  documentContainer.signatures.length > 0
                    ? t("completed")
                    : t("in-progress")}
                </h4>
              </div>

              <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                <h6 className={classes.cardCategory} style={{ width: "50px" }}>
                  INDUSTRY
                </h6>
                <h4 style={{ marginLeft: "20px" }}>
                  {documentContainer.message.industry}
                </h4>
              </div>

              <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                <h6 className={classes.cardCategory} style={{ width: "50px" }}>
                  YEAR
                </h6>
                <h4 style={{ marginLeft: "20px" }}>
                  {!isObjectEmpty(documentContainer) &&
                    documentContainer.message.year}
                </h4>
              </div>

              <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                <h6 className={classes.cardCategory} style={{ width: "50px" }}>
                  CREATED
                </h6>
                <h4 style={{ marginLeft: "20px" }}>
                  {!isObjectEmpty(documentContainer) &&
                    formatDate(documentContainer.createdAt)}
                </h4>
              </div>

              {!isObjectEmpty(documentContainer) && !isObjectEmpty(auditor) && (
                <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                  <h6
                    className={classes.cardCategory}
                    style={{ width: "50px" }}
                  >
                    AUDITOR
                  </h6>
                  <div style={{ display: "inline-flex" }}>
                    <h4 style={{ marginLeft: "20px" }}>{auditor.email}</h4>
                    {role === "owner" && (
                      <Tooltip title={t("delete-auditor")}>
                        <IconButton
                          aria-label="delete"
                          style={{ fontSize: "1em" }}
                          onClick={handleDeleteAuditorAccess}
                        >
                          <ClearIcon
                            style={{
                              color: "#a5abbd",
                              fontSize: "1em",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {documentContainer.signatures.length === 1 && (
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                          marginLeft: "10px",
                        }}
                      >
                        <Tooltip title={t("view-on-chain")}>
                          <IconButton
                            aria-label="view-on-chain"
                            style={{
                              color: "#3F91F9",
                            }}
                            onClick={() =>
                              handleOpenBCModal(documentContainer.signatures)
                            }
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("view-on-chain")}>
                          <IconButton
                            style={{
                              color: "#3F91F9",
                            }}
                            onClick={() =>
                              handleOpenVerifyHashModal(
                                documentContainer.signatures
                              )
                            }
                          >
                            <CheckIcon className={classes.icons} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </GridItem>

            <GridItem xs={12} sm={6} md={6} className={classes2.chip}>
              {role === "owner" &&
                !isObjectEmpty(documentContainer) &&
                isObjectEmpty(auditor) && (
                  <Button
                    color="primary"
                    simple
                    onClick={handleOpenInviteAuditorModal}
                  >
                    {t("invite-auditor")}
                  </Button>
                )}
              {role === "owner" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShowAddMetric}
                >
                  {t("add-metric")}
                </Button>
              )}

              {signLoading && (
                <div className={classes.root}>
                  <LinearProgress
                    style={{ marginTop: "10px", marginBottom: "15px" }}
                  />
                </div>
              )}

              {role === "auditor" && documentContainer.signatures.length === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenSignModal}
                >
                  {t("sign-assessment")}
                </Button>
              )}
               <Button
                variant="contained"
                color="rose"
                onClick={handleDownloadReport}
              >
                {t("download-report")}
              </Button> 
            </GridItem>
          </GridContainer>
          {!showAddMetric &&
            envHeaders.length === 0 &&
            socHeaders.length === 0 &&
            govHeaders.length === 0 && (
              <>
                <Card pricing plain>
                  <CardBody pricing>
                    <div className={ppClasses.icon}>
                      <TrendingUpIcon className={ppClasses.iconRose} />
                    </div>
                    <h5
                      className={`${ppClasses.cardTitle} ${ppClasses.marginTop30}`}
                    >
                      {t("no-metrics-created")}
                    </h5>
                    <p className={ppClasses.cardDescription}>
                      {t("start-creating-assessments-by-clicking-add-metric")}
                    </p>
                  </CardBody>
                </Card>
              </>
            )}
          {showAddMetric && addMetric}
          {envHeaders.length > 0 && (
            <>
              <Card
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  margin: "0px 0px 0px 0px",
                  padding: "0px 0px",
                }}
              >
                <CardHeader color="primary" icon style={{ margin: "0px 0px" }}>
                  <CardIcon color="primary">
                    <EcoIcon />
                  </CardIcon>
                  <h4
                    className={classes.cardIconTitle}
                    style={{ color: "#3c4858" }}
                  >
                    {t("environmental")}
                  </h4>
                </CardHeader>
              </Card>
              <ExpansionPanelComponent
                panelSummary={<ItemExpansionPanelSummary item={headerText} />}
                disabled={true}
              />
              {envHeaders.map((metric, index) => {
                return (
                  <div style={{ marginTop: "10px" }} key={`metric-${index}`}>
                    <ExpansionPanelComponent
                      panelSummary={<ItemExpansionPanelSummary item={metric} />}
                      panelDetails={
                        <ItemExpansionPanelDetails
                          item={metric}
                          id={containerId}
                          handleUpdateView={handleUpdateView}
                          profile={userProfile}
                          accessRights={documentContainer.accessRights}
                          refreshContainer={refreshContainer}
                          role={role}
                          containerOwner={containerOwner}
                          lookupUsers={lookupUsers}
                        />
                      }
                    />
                  </div>
                );
              })}
            </>
          )}
          {socHeaders.length > 0 && (
            <>
              <Card
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  marginBottom: "0px",
                  padding: "0px 0px",
                }}
              >
                <CardHeader color="primary" icon style={{ margin: "0px 0px" }}>
                  <CardIcon color="primary">
                    <PeopleIcon />
                  </CardIcon>
                  <h4
                    className={classes.cardIconTitle}
                    style={{ color: "#3c4858" }}
                  >
                    {t("social")}
                  </h4>
                </CardHeader>
              </Card>
              <ExpansionPanelComponent
                panelSummary={<ItemExpansionPanelSummary item={headerText} />}
                disabled={true}
              />
              {socHeaders.map((metric, index) => {
                return (
                  <div style={{ marginTop: "10px" }} key={`metric-${index}`}>
                    <ExpansionPanelComponent
                      panelSummary={<ItemExpansionPanelSummary item={metric} />}
                      panelDetails={
                        <ItemExpansionPanelDetails
                          item={metric}
                          id={containerId}
                          handleUpdateView={handleUpdateView}
                          profile={userProfile}
                          accessRights={documentContainer.accessRights}
                          refreshContainer={refreshContainer}
                          role={role}
                          containerOwner={containerOwner}
                          lookupUsers={lookupUsers}
                        />
                      }
                    />
                  </div>
                );
              })}
            </>
          )}
          {govHeaders.length > 0 && (
            <>
              <Card
                style={{
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  marginBottom: "0px",
                  padding: "0px 0px",
                }}
              >
                <CardHeader color="primary" icon style={{ margin: "0px 0px" }}>
                  <CardIcon color="primary">
                    <GavelIcon />
                  </CardIcon>
                  <h4
                    className={classes.cardIconTitle}
                    style={{ color: "#3c4858" }}
                  >
                    {t("governance")}
                  </h4>
                </CardHeader>
              </Card>
              <ExpansionPanelComponent
                panelSummary={<ItemExpansionPanelSummary item={headerText} />}
                disabled={true}
              />
              {govHeaders.map((metric, index) => {
                return (
                  <div style={{ marginTop: "10px" }} key={`metric-${index}`}>
                    <ExpansionPanelComponent
                      panelSummary={<ItemExpansionPanelSummary item={metric} />}
                      panelDetails={
                        <ItemExpansionPanelDetails
                          item={metric}
                          id={containerId}
                          handleUpdateView={handleUpdateView}
                          profile={userProfile}
                          accessRights={documentContainer.accessRights}
                          refreshContainer={refreshContainer}
                          role={role}
                          containerOwner={containerOwner}
                          lookupUsers={lookupUsers}
                        />
                      }
                    />
                  </div>
                );
              })}
            </>
          )}
          {openSignModal && (
            <ModalComponent
              open={openSignModal}
              handleClose={handleCloseSignModal}
              dialogTitle={t("sign-this-assessment")}
              maxWidth="xs"
              dialogContent={
                <div>{t("are-you-sure-you-want-to-sign-this-assessment")}</div>
              }
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseSignModal} color="rose">
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignAssessment}
                  >
                    {t("sign")}
                  </Button>
                </React.Fragment>
              }
            />
          )}

          {openInviteAuditorModal && (
            <ModalComponent
              open={openInviteAuditorModal}
              handleClose={handleCloseModal}
              dialogTitle={t("invite-auditor")}
              maxWidth="xs"
              dialogContent={
                <>
                  <CustomInput
                    labelText={t("email")}
                    id="help-text"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: auditorEmail || "",
                      onChange: (e) => {
                        setAuditorEmail(e.target.value);
                      },
                      placeholder: t("email"),
                    }}
                  />
                </>
              }
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseModal} color="rose">
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendInvite}
                  >
                    {t("invite")}
                  </Button>
                </React.Fragment>
              }
            />
          )}

          <ModalComponent
            open={openBCModal}
            handleClose={handleCloseBCModal}
            dialogTitle={t("view-on-chain")}
            maxWidth="xs"
            dialogContent={
              <>
                {!isObjectEmpty(signatureDetails) ? (
                  <div>
                    {signatureDetails.transactionStatus === "PREPARED" ? (
                      <p>
                        {t("transaction-is-presigned-but-not-on-blockchain")}
                      </p>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <QRCode
                          size={200}
                          value={`https://whatsonchain.com/tx/${signatureDetails.transactionId}`}
                        />
                        <div style={{ textAlign: "center", marginTop: "2rem" }}>
                          <a
                            href={`https://whatsonchain.com/tx/${signatureDetails.transactionId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {"link"}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            }
          />

          <ModalComponent
            open={openVerifyHashModal}
            handleClose={handleCloseVerifyHashModal}
            dialogTitle={t("document-hash-signature-verification")}
            maxWidth="md"
            dialogContent={
              <>
                {verificationData.length > 0 && (
                  <HashTimeline steps={verificationData} />
                )}
              </>
            }
          />

          <SnackBarComponent
            open={openSnackbar}
            handleClose={handleCloseSnackbar}
            variant={variant}
            message={snackbarMessage}
          />
        </>
      )}
    </>
  );
};

const useppStyles = makeStyles(ppStyles);
const useStyles = makeStyles({
  ...cardTitle,
  ...styles,
  cardIconTitle: {
    marginTop: "35px",
  },
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#e8d1b3",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DBB686",
    },
  },
});

const useStyles2 = makeStyles(() => ({
  chip: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    "@media (max-width: 599px)": {
      justifyContent: "flex-start",
    },
  },
}));

export default AssessmentDetailsPage;
