import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import ApiService from "api/ApiService.js";
import { Link } from "react-router-dom";
import { formatDate } from "api/utils";
import SnackBarComponent from "components/SnackBarComponent.js";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "@material-ui/lab/Pagination";
import Chip from "@material-ui/core/Chip";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ListIcon from "@material-ui/icons/List";
import IconButton from "@material-ui/core/IconButton";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import extendedTablesStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ClearIcon from "@material-ui/icons/Clear";
import ModalComponent from "components/ModalComponent.js";
import "./style.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#4b679d",
      "&:hover": {
        backgroundColor: "#4b679d",
      },

      "&:active": {
        backgroundColor: "#4b679d",
      },
    },
  },
  chip: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
  },
  chipColorPrimary: {
    color: "#dbb686",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  chipColorSecondary: {
    color: "#dbb686",
    fontWeight: "bold",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  ...extendedTablesStyles,
}));

const Documents = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [myFiles, setMyFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // pagination
  const [sendDataCount, setSendDataCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [userProfile, setUserProfile] = React.useState({});

  const [documentParams, setDocumentParams] = React.useState({});
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  // list view
  const [showListView, setShowListView] = React.useState(true);

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleRestoreFromBin = async (data_id, sender, receiver, hash) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      data_id,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([{ name: "abendum", value: true }]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      await handleRefresh();
    } catch (e) {
      console.error(e);
      setSnackbarMessage(e.message);
      setVariant("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteFile = async (data_id, sender, receiver, hash) => {
    setOpenDeleteModal(true);

    setDocumentParams({ data_id, sender, receiver, hash });
  };

  // delete file
  const handleDeleteFile = async (data_id, sender, receiver, hash) => {
    setOpenDeleteModal(false);

    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.deleteFile(
        {
          data_id: documentParams.data_id,
          hash: documentParams.hash,
          sender: documentParams.sender,
          receiver: documentParams.receiver,
        },
        signal
      );
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
      return;
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          setOpenSnackbar(true);
          setVariant("error");
          setSnackbarMessage(t("error-deleting-file"));
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setOpenSnackbar(true);
          setVariant("success");
          setSnackbarMessage(t("file-deleted"));
          setDocumentParams({});
          await handleRefresh();
        }
      }
      if (signal.aborted) {
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(t("operation-aborted"));
        setDocumentParams({});
      }
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(t("error-deleting-file"));
      setDocumentParams({});
    }
  };

  const getQueryParams = React.useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;

      return params;
    },
    [props.location.search]
  );

  const handleRefresh = React.useCallback(
    async (signal = undefined) => {
      const getSendData = async (signal = undefined, queryParams) => {
        try {
          const myAddress = await ApiService.getPublicKey(
            {
              userId: queryParams.userId,
              serviceType: "SDC",
              keyType: "MASTER",
            },
            signal
          );

          let params;
          if (showListView) {
            params = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              receiver: myAddress.address,
              populateSignatures: true,
              populateMetadata: true,
            };
          } else {
            params = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              fromEntry: (currentPage - 1) * pageSize,
              numberOfResults: pageSize,
              receiver: myAddress.address,
            };
          }

          if (!ApiService.production) {
            params.filters = JSON.stringify({
              $and: [{ "flags.name": "abendum" }, { "flags.name": "bin" }],
            });
          } else {
            params.filters = JSON.stringify({ "flags.name": "bin" });
          }

          if (queryParams.parentId) {
            params.parentId = queryParams.parentId;
          }
          const returnData = await ApiService.getSendData(params, signal);

          setMyFiles(returnData.filter((file) => file.parentId === undefined));

          if (!showListView) {
            let getSendDataCount;
            if (!ApiService.production) {
              getSendDataCount = await ApiService.getSendDataCount({
                filters: JSON.stringify({
                  $and: [{ "flags.name": "abendum" }, { "flags.name": "bin" }],
                }),
              });
            } else {
              getSendDataCount = await ApiService.getSendDataCount({
                filters: JSON.stringify({ "flags.name": "bin" }),
              });
            }

            setSendDataCount(getSendDataCount.count);

            const total = Math.ceil(getSendDataCount.count / pageSize);
            setTotalPages(total);
          }
        } catch (e) {
          console.error(e);
          setSnackbarMessage(e.message);
          setVariant("error");
          setOpenSnackbar(true);
        }
      };
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getQueryParams, currentPage, pageSize, showListView]
  );

  const getIcon = (fileType) => {
    if (fileType === "text/plain") {
      return <i className="far fa-file"></i>;
    } else if (fileType === "text/csv") {
      return <i className="fas fa-file-csv"></i>;
    } else if (fileType === "text/html") {
      return <i className="far fa-file-code"></i>;
    } else if (fileType.startsWith("image")) {
      return <i className="far fa-file-image"></i>;
    } else if (fileType === "application/pdf") {
      return <i className="far fa-file-pdf"></i>;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/vnd.oasis.opendocument.text"
    ) {
      return <i className="far fa-file-word"></i>;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return <i className="far fa-file-powerpoint"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="far fa-file-excel"></i>;
    } else if (
      fileType === "application/zip" ||
      fileType === "application/x-7z-compressed" ||
      fileType === "application/vnd.rar"
    ) {
      return <i className="far fa-file-archive"></i>;
    } else {
      return <i className="far fa-file-alt"></i>;
    }
  };

  // pagination
  const handleChangePage = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleClick = (newPageSize) => {
    const total = Math.ceil(sendDataCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(currentPage / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * currentPage);
    }
    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };

  const setDocumentType = (docType) => {
    if (docType === "Outbound invoice") {
      return t("sent-invoice")
    } else if (docType === "Inbound invoice") {
      return t("received-invoice")
    } else if (docType === "Other") {
      return t("other")
    }
  }

  const prepareDataForTable = (myFiles) => {
    const checkConfirmed = (signatures) => {
      if (signatures && signatures.length > 1) {
        const signSet = new Set();
        signatures.map((sig) => signSet.add(sig.sender));
        if (signSet.size >= 2) {
          return t("confirmed");
        } else {
          return t("not-confirmed");
        }
      } else {
        return t("not-confirmed");
      }
    };
    return myFiles.map((file) => {
      return {
        title: file.title,
        createdAt: formatDate(file.createdAt),
        organizationNumber: file?.metadata?.find(
          (data) => data.name === "Customer organization number"
        )?.metadata,
        invoiceNumber: file?.metadata?.find(
          (data) => data.name === "Invoice number"
        )?.metadata,
        documentType: setDocumentType(file?.metadata.find((data) => data.name === "Document Type")?.metadata),
        confirmedStatus: checkConfirmed(file?.signatures),
        actions: (
          <Button
            color="primary"
            onClick={() =>
              handleRestoreFromBin(
                file.data_id,
                file.sender,
                file.receiver,
                file.hash
              )
            }
          >
            {t("restore")}
          </Button>
        ),
      };
    });
  };

  React.useEffect(() => {
    let isMounted = true;
    const apiOperations = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      setLoading(true);
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, false));
      } catch (e) {
        console.error(e);
        setSnackbarMessage(e.message);
        setVariant("error");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [handleRefresh, showListView]);

  return (
    <>
      {loading && <Loading />}
      {!loading &&
      myFiles.length === 0 &&
      !isObjectEmpty(userProfile) &&
      userProfile.validatedDocuments &&
      userProfile.validatedEmail ? (
        <>
          <Card pricing plain>
            <CardBody pricing>
              <div className={classes.icon}>
                <DeleteOutlineIcon className={classes.iconRose} />
              </div>
              <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                {t("bin-empty")}
              </h5>
            </CardBody>
          </Card>
        </>
      ) : (
        <GridContainer>
          {!loading &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail && (
              <GridItem xs={12} className={classes2.chip}>
                {!showListView && (
                  <>
                    <Chip
                      classes={{
                        colorPrimary: classes2.chipColorPrimary,
                        colorSecondary: classes2.chipColorSecondary,
                      }}
                      label="10"
                      onClick={() => handleClick(10)}
                      color={pageSize === 10 ? "secondary" : "primary"}
                      style={{ marginRight: "5px" }}
                    />
                    <Chip
                      classes={{
                        colorPrimary: classes2.chipColorPrimary,
                        colorSecondary: classes2.chipColorSecondary,
                      }}
                      label="20"
                      color={pageSize === 20 ? "secondary" : "primary"}
                      onClick={() => handleClick(20)}
                      style={{ marginRight: "5px" }}
                    />
                    <Chip
                      classes={{
                        colorPrimary: classes2.chipColorPrimary,
                        colorSecondary: classes2.chipColorSecondary,
                      }}
                      label="100"
                      onClick={() => handleClick(100)}
                      color={pageSize === 100 ? "secondary" : "primary"}
                      style={{
                        borderRight: "1px solid #dbb686",
                        borderRadius: "0px",
                      }}
                    />
                  </>
                )}

                {showListView ? (
                  <Tooltip title={t("grid-view")}>
                    <IconButton
                      aria-label="grid-view"
                      onClick={() => setShowListView(false)}
                    >
                      <ViewComfyIcon style={{ color: "#dbb686" }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("list-view")}>
                    <IconButton
                      aria-label="list-view"
                      onClick={() => setShowListView(true)}
                    >
                      <ListIcon style={{ color: "#dbb686" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </GridItem>
            )}
          {!loading &&
            !showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.map((file) => {
              return (
                <>
                  <GridItem xs={6} sm={6} md={4} lg={3} key={file.data_id}>
                    <Card pricing>
                      <CardBody pricing>
                        <div
                          className={classes.cardCategory}
                          style={{ textAlign: "right", marginTop: "-10px" }}
                        >
                          <Tooltip title={t("delete")}>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleOpenDeleteFile(
                                  file.data_id,
                                  file.sender,
                                  file.receiver,
                                  file.hash
                                )
                              }
                              style={{ fontSize: "1em" }}
                            >
                              <ClearIcon
                                style={{ color: "#a5abbd", fontSize: "1em" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className={classes.icon}>
                          <div className={classes.iconRose}>
                            {getIcon(file.dataType)}
                          </div>
                        </div>
                        <h6
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          {formatDate(file.createdAt)}
                        </h6>
                        <h5 className={`${classes.cardTitle} `}>
                          {file.title}
                        </h5>
                        <p className={classes.cardDescription}></p>

                        <Button
                          color="primary"
                          onClick={() =>
                            handleRestoreFromBin(
                              file.data_id,
                              file.sender,
                              file.receiver,
                              file.hash
                            )
                          }
                        >
                          {t("restore")}
                        </Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                </>
              );
            })}
          {!loading &&
            showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.length > 0 && (
              <>
                <GridItem xs={12}>
                  <Card>
                    <CardBody>
                      <ReactTable
                        data={prepareDataForTable(myFiles)}
                        sortable={true}
                        filterable={true}
                        columns={[
                          {
                            Header: t("title"),
                            accessor: "title",
                          },
                          {
                            Header: t("created-at"),
                            accessor: "createdAt",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("organization-number"),
                            accessor: "organizationNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("invoice-number"),
                            accessor: "invoiceNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("document-type"),
                            accessor: "documentType",
                          },
                          {
                            Header: t("confirmed-status"),
                            accessor: "confirmedStatus",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                        ]}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        defaultPageSize={10}
                        nextText={t("next")}
                        previousText={t("previous")}
                        loadingText={t("loading")}
                        noDataText={t("no-data")}
                        pageText={t("page")}
                        ofText={t("of")}
                        rowsText={t("rows")}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            )}
          <GridItem xs={12}>
            {!loading &&
              !isObjectEmpty(userProfile) &&
              userProfile.validatedDocuments &&
              userProfile.validatedEmail &&
              !showListView && (
                <Pagination
                  size="small"
                  count={totalPages}
                  color="secondary"
                  page={currentPage}
                  onChange={(event, page) => handleChangePage(page)}
                  className={classes2.root}
                />
              )}
          </GridItem>
        </GridContainer>
      )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  {!userProfile.requestDocumentsValidation ? (
                    <SupervisedUserCircleIcon className={classes.iconPrimary} />
                  ) : (
                    <HourglassEmptyIcon className={classes.iconPrimary} />
                  )}
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {!userProfile.requestDocumentsValidation
                    ? t("in-order-to-proceed")
                    : t("waiting-for-document-validation")}
                </h5>
                {!userProfile.requestDocumentsValidation ? (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail && t("validate-email")}
                    {!userProfile.validatedEmail &&
                      (!userProfile.validatedDocuments ? " and " : ".")}
                    {!userProfile.validatedDocuments &&
                      t("request-a-document-validation")}
                  </p>
                ) : (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail &&
                      t("please-validate-your-email")}
                  </p>
                )}
                <Link to="/admin/user-page" style={{ color: "#D7A31A" }}>
                  {t("go-to-user-profile")}
                </Link>
              </CardBody>
            </Card>
          </>
        )}
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={snackbarMessage}
      />

      <ModalComponent
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        dialogTitle={t("delete-document")}
        maxWidth="xs"
        dialogContent={<div>{t("confirm-delete-document")}</div>}
        dialogActions={
          <React.Fragment>
            <Button simple onClick={handleCloseDeleteModal} color="rose">
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteFile}
            >
              {t("yes")}
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
};

export default Documents;
