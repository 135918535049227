import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  lightBlueCardHeader,
  veryLightBlueCardHeader,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$lightBlueCardHeader,&$veryLightBlueCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "15px",
      marginRight: "15px",
      float: "left",
      color: "#fff"
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  lightBlueCardHeader,
  veryLightBlueCardHeader
};

export default cardIconStyle;
