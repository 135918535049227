import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Timeline from "components/Timeline/Timeline.js";
import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "components/Accordion/Accordion.js";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import CodeIcon from "@material-ui/icons/Code";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import CheckIcon from "@material-ui/icons/Check";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import DescriptionIcon from "@material-ui/icons/Description";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const HashTimeline = ({ steps, inputData = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let final = 4;

  const stories = [
    {
      // First story
      inverted: true,
      badgeColor: "primary",
      badgeIcon: SyncAltIcon,
      title: steps[0].stage,
      titleColor: "primary",
      body: (
        <GridContainer>
          {steps[0].details.map(([fieldName, fieldValue]) => {
            let data;
            if (typeof fieldValue === "object") {
              data = (
                <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {JSON.stringify(fieldValue, undefined, 2)}
                </div>
              );
            } else {
              data = (
                <p style={{ wordBreak: "break-all" }}>
                  {fieldValue.toString()}
                </p>
              );
            }
            return (
              <React.Fragment key={fieldName}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{fieldName}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    },
    {
      inverted: true,
      // Second story
      badgeColor: "info",
      badgeIcon: CodeIcon,
      title: steps[1].stage,
      titleColor: "info",
      body: (
        <GridContainer>
          {steps[1].details.map(([fieldName, fieldValue]) => {
            let data;
            if (typeof fieldValue === "object") {
              data = (
                <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {JSON.stringify(fieldValue, undefined, 2)}
                </div>
              );
            } else {
              data = (
                <p style={{ wordBreak: "break-all" }}>
                  {fieldValue.toString()}
                </p>
              );
            }
            return (
              <React.Fragment key={fieldName}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{fieldName}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    },
    {
      inverted: true,
      // Second story
      badgeColor: "warning",
      badgeIcon: LockIcon,
      title: steps[2].stage,
      titleColor: "warning",
      body: (
        <GridContainer>
          {steps[2].details.map(([fieldName, fieldValue]) => {
            let data;
            if (typeof fieldValue === "object") {
              data = (
                <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {JSON.stringify(fieldValue, undefined, 2)}
                </div>
              );
            } else {
              data = (
                <p style={{ wordBreak: "break-all" }}>
                  {fieldValue.toString()}
                </p>
              );
            }
            return (
              <React.Fragment key={fieldName}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{fieldName}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    },
    {
      inverted: true,
      // Second story
      badgeColor: "danger",
      badgeIcon: EmailIcon,
      title: steps[3].stage,
      titleColor: "danger",
      body: (
        <GridContainer>
          {steps[3].details.map(([fieldName, fieldValue]) => {
            let data;
            if (typeof fieldValue === "object") {
              data = (
                <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {JSON.stringify(fieldValue, undefined, 2)}
                </div>
              );
            } else {
              data = (
                <p style={{ wordBreak: "break-all" }}>
                  {fieldValue.toString()}
                </p>
              );
            }
            return (
              <React.Fragment key={fieldName}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{fieldName}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    },
    {
      inverted: true,
      // Second story
      badgeColor: "success",
      badgeIcon: CheckIcon,
      title: steps[4].stage,
      titleColor: "success",
      body: (
        <GridContainer>
          {steps[4].details.map(([fieldName, fieldValue]) => {
            let data;
            if (typeof fieldValue === "object") {
              data = (
                <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                  {JSON.stringify(fieldValue, undefined, 2)}
                </div>
              );
            } else {
              data = (
                <p style={{ wordBreak: "break-all" }}>
                  {fieldValue.toString()}
                </p>
              );
            }
            return (
              <React.Fragment key={fieldName}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{fieldName}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    },
  ];

  if (!isObjectEmpty(inputData)) {
    const additionalStory = {
      inverted: true,
      // Second story
      badgeColor: "lightBlue",
      badgeIcon: DescriptionIcon,
      title: t("input-data"),
      titleColor: "lightBlue",
      body: (
        <GridContainer>
          {Object.keys(inputData).map((key, index) => {
            const data = (
              <p style={{ wordBreak: "break-all" }}>
                {inputData[key].toString()}
              </p>
            );

            return (
              <React.Fragment key={key}>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                  <h6>{key}</h6>
                </GridItem>
                <GridItem xs={12}>{data}</GridItem>
              </React.Fragment>
            );
          })}
        </GridContainer>
      ),
    };

    stories.unshift(additionalStory);
  }

  const timelineBadgeClasses =
    classes.timelineBadge +
    " " +
    classes[steps[final].details[4][1] === "success" ? "success" : "danger"] +
    " " +
    cx({
      [classes.timelineSimpleBadge]: true,
    });

  return (
    <>
      <GridContainer alignItems="center" justify="space-between">
        <GridItem xs={4}></GridItem>
        {steps[final].details[4][1] === "success" &&
        steps[final].details[5][1] === "success" &&
        steps[final].details[6][1] === "success" ? (
          <>
            <GridItem xs={2}>
              <div
                className={timelineBadgeClasses}
                style={{ position: "sticky" }}
              >
                <CheckCircleOutlineIcon className={classes.badgeIcon} />
              </div>
            </GridItem>
            <GridItem xs={6}>{t("verification-successful")}</GridItem>
          </>
        ) : (
          <>
            <GridItem xs={2}>
              <div
                className={timelineBadgeClasses}
                style={{ position: "sticky" }}
              >
                <HighlightOffIcon className={classes.badgeIcon} />
              </div>
            </GridItem>
            <GridItem xs={6}>{t("verification-failed")}</GridItem>
          </>
        )}
      </GridContainer>
      {/* <GridContainer alignItems="center" justify="space-between">
        <GridItem xs={4}></GridItem>
        <GridItem xs={2}>
          {steps[final].details[4][1] === "success" ? (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <CheckCircleOutlineIcon className={classes.badgeIcon} />
            </div>
          ) : (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <HighlightOffIcon className={classes.badgeIcon} />
            </div>
          )}
        </GridItem>
        <GridItem xs={6}>{steps[final].details[4][0]}</GridItem>
      </GridContainer>
      <GridContainer alignItems="center" style={{ marginTop: "10px" }}>
        <GridItem xs={4}></GridItem>
        <GridItem xs={2}>
          {steps[final].details[5][1] === "success" ? (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <CheckCircleOutlineIcon className={classes.badgeIcon} />
            </div>
          ) : (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <HighlightOffIcon className={classes.badgeIcon} />
            </div>
          )}
        </GridItem>
        <GridItem xs={6}>{steps[final].details[5][0]}</GridItem>
      </GridContainer>
      <GridContainer alignItems="center" style={{ marginTop: "10px" }}>
        <GridItem xs={4}></GridItem>
        <GridItem xs={2}>
          {steps[final].details[6][1] === "success" ? (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <CheckCircleOutlineIcon className={classes.badgeIcon} />
            </div>
          ) : (
            <div
              className={timelineBadgeClasses}
              style={{ position: "sticky" }}
            >
              <HighlightOffIcon className={classes.badgeIcon} />
            </div>
          )}
        </GridItem>
        <GridItem xs={6}>{steps[final].details[6][0]}</GridItem>
      </GridContainer> */}
      <GridContainer style={{ margionTop: "35px" }}>
        <GridItem xs={12}>
          <Accordion
            collapses={[
              {
                title: t("view-details"),
                content: <Timeline simple stories={stories} />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default HashTimeline;
