import React from "react";
import Wizard from "components/Wizard/Wizard.js";

import Step1 from "views/Forms/WizardSteps/Step1.js";
import Step2 from "views/Forms/WizardSteps/Step2.js";
import Step3 from "views/Forms/WizardSteps/Step3.js";

const UploadStepper = (props) => {
    let steps;
    if (props.containerId) {
        steps = [
            { stepName: "Select & Upload", stepComponent: Step1, stepId: "select-upload" },
            { stepName: "Classify & Add", stepComponent: Step2, stepId: "classify-add" },
        ]
    } else {
        steps = [
            { stepName: "Select & Upload", stepComponent: Step1, stepId: "select-upload" },
            { stepName: "Classify & Add", stepComponent: Step2, stepId: "classify-add" },
            { stepName: "Sign & Send", stepComponent: Step3, stepId: "sign-send" },
        ]
    }

    return (
         <Wizard
         validate
          steps={steps}
          title="Document upload"
          finishButtonClick={props.handleFinishButton}
          containerId={props.containerId ? props.containerId : ""}
          handleCancelUpload={props.handleCancelUpload}
        />
    )
};

export default UploadStepper;
